import React from "react";
import * as api from "../../api/book_airline";
import "./styles.css";
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import { Select, Icon, Button, DatePicker, Modal, Radio, Checkbox } from 'antd';
import SearchInputDeparture from '../../components/searchInput' 
const { RangePicker } = DatePicker;
var moment = require('moment');
const DISABLE = "disabled-price";
const ONE_AWAY = 1;
const ROUND_TRIP = 2;
const { Option } = Select;

const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DDTHH:mm:ss.SSSZ`];

export default class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      totalPages: 1,
      staffId: 0,
      users: [],
      valueType: null, 
      modalIsOpenCustomer: false,
      item:{contact:{}},
      index: 0,
      status: 0
    };
  }

  componentDidMount() {
    this.getBooks()
  }

  getBooks(){
    api.getBookByGroup(0, this.state.status).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: response.content.length === 0 ? response.pageable.pageNumber : response.pageable.pageNumber + 1,
          totalPages: response.totalPages
        });
      }
    });
  }

  closeModal = () =>{
    this.setState({
      modalIsOpenCustomer: false
    });
  }

  onPageChange = page => {
    this.setState({ page }, () => {
      api.getBookByGroup(this.state.page - 1, this.state.status).then(response => {
        if (response !== undefined) {
          this.setState({
            data: response.content,
            page: response.pageable.pageNumber +1
          });
        }
      });
    });
  };

  onChangeCheckbox=(e, item)=> {
    var checked = e.target.checked
    item.support = checked
    this.setState({item})
  }

  renderItem(item, index) {
    return (
      <tr key={item.id} className="row m-0">
        <td className="align-middle col-2">{item.contact.lastName + ' ' +item.contact.firstName}</td>
        <td className="align-middle col-3">{item.contact.email}</td>
        <td className="align-middle col-3">{item.contact.phone}</td>
        <th className="align-middle col-3">{`${item.departureCode} - ${item.returnCode} (${moment(item.departureDate).format('HH:mm MM/DD/YYYY')})`}</th>
        <td className="align-middle col-1">
          <Icon type="edit" style={{color:"black"}} onClick={()=>{this.setState({modalIsOpenCustomer: true, item, index})}}/>
        </td>
      </tr>
    );
  }

  updateBook=()=>{
    const{item} = this.state;
    api.updateGroupTicket(item.id, item).then(response => {
      if (response !== undefined) {
        alert("Cập nhật trạng thái thành công")
        this.closeModal()
        this.getBooks()
      }
    });
  }

  handleChangeStatus = event => {
    this.setState({ status: event });
  };

  render() {
    const{item, status} = this.state
    return (
      <div className="container text-center">
        <div className="text-right">
          <Select value={status} className="select-view mr-3" onChange={this.handleChangeStatus}>
            <Option key={0} value={0}>Tất cả</Option>
            <Option key={1} value={1}>Chưa liên hệ</Option>
            <Option key={2} value={2}>Đã liên hệ</Option>
          </Select>
          <Button type="primary" onClick={()=>this.getBooks()}>Lọc dữ liệu</Button>
        </div>
        <Modal
          width={800}
          visible={this.state.modalIsOpenCustomer}
          confirmLoading={false}
          onCancel={this.closeModal}
          footer={[
            <Button key="back" onClick={this.closeModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" loading={this.state.loading} onClick={this.updateBook}>
              Lưu
            </Button>
          ]}
        >
        <div className="row mt-3" >
          <div className={`${DISABLE} col-6`}>
            <h6>Điểm đi</h6>
            <SearchInputDeparture placeholder="" style={{ width: 300 }} value={this.handleChangeDepartureAirportCode}>{item.departureCode}</SearchInputDeparture>

            <h6 className="mt-3">Điểm đến:</h6>
            <SearchInputDeparture placeholder="" style={{ width: 300 }} value={this.handleChangeDestinationAirportCode}>{item.returnCode}</SearchInputDeparture>

            <h6 className="mt-3">Ngày đi:</h6>
            <RangePicker
              className="mb-3"
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder={['Start Time', 'End Time']}
              onOk={this.onOkOneWay}
              allowClear={false}
              defaultValue={[moment(item.departureDate, dateFormatList), moment(item.departureDate, dateFormatList)]}
              onChange={(dates)=> this.onOkOneWay(dates)}
              value={[moment(item.departureDate, dateFormatList), moment(item.departureDate, dateFormatList)]}
            />
            
            <div>
              <h6 >Ngày về:</h6>
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                placeholder={['Start Time', 'End Time']}
                onOk={this.onOkRoundTrip}
                allowClear={false}
                defaultValue={[moment(item.returnDate, dateFormatList), moment(item.returnDate, dateFormatList)]}
                onChange={(dates)=> this.onOkRoundTrip(dates)}
                value={[moment(item.returnDate, dateFormatList), moment(item.returnDate, dateFormatList)]}
              />
            </div>
            <h6 className="mt-3">Loại vé:</h6>
            <Radio.Group onChange={this.onChange} value={item.itineraryType}>
              <Radio value={ONE_AWAY}>1 chiều</Radio>
              <Radio value={ROUND_TRIP}>Khứ hồi</Radio>
            </Radio.Group>
          </div>
          <div className="col-6">
              <h6 >Thông tin liên hệ:</h6>
              <p>{`Tên: ${item.contact.lastName} ${item.contact.firstName}`}</p>
              <p>{`Email: ${item.contact.email}`}</p>
              <p>{`Giới tính: ${item.contact.gender ? 'Nam' : 'Nữ'}`}</p>
              <p>{`SDT: ${item.contact.phone}`}</p>

              <h6 >Số lượng khách hàng:</h6>
              <p>{`Người lớn: ${item.adult}`}</p>
              <p>{`Trẻ em: ${item.child}`}</p>
              <p>{`Em bé: ${item.infant}`}</p>

              <Checkbox checked={item.support} onChange={(e)=>{this.onChangeCheckbox(e, item)}}>Hỗ trợ khách hàng</Checkbox>
            </div>
        </div>
        </Modal>

        <table className="table mt-3 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-2">Tên</th>
              <th className="col-3">Email</th>
              <th className="col-3">SDT</th>
              <th className="col-3">Chặng bay</th>
              <th className="col-1"></th>
            </tr>
          </thead>
          <tbody>{this.state.data.map((item, index) => this.renderItem(item, index))}</tbody>
        </table>
        <div style={{display: this.state.data.length !== 0 ? 'block' : 'none' }}>
          <ReactUltimatePagination
            currentPage={this.state.page}
            totalPages={this.state.totalPages}
            onChange={this.onPageChange}
          />
        </div>
      </div>
    );
  }
}
