import * as util from '../util'
const instance = util.instance

export function getAirline(page,key) {
  return instance
    .get(`/airlines?page=${page}&&key=${key}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}
export function updateAirline(id,data) {
    return instance
      .post(`/airlines/airline/${id}`,data)
      .then(response => {
        return response.data;
      })
      .catch(util.checkError);
}
export function deleteDetailAirline(id) {
    return instance
      .delete(`/class/delete/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(util.checkError);
}
export function updateDetail(id,data) {
    return instance
      .post(`/class/update/${id}`,data)
      .then(response => {
        return response.data;
      })
      .catch(util.checkError);
}
export function addDetail(data) {
    return instance
      .post("/class/create",data)
      .then(response => {
        return response.data;
      })
      .catch(util.checkError);
}

