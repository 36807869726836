import React from "react";
import { Bar, Pie } from 'ant-design-pro/lib/Charts';
// import 'ant-design-pro/dist/ant-design-pro.css'; // Import whole style

export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesData: [],
      salesPieData: []
    };
  }

  componentDidMount() {
    this.getDataInYear()
    this.getDataByPaymentMethod()
  }

  getDataInYear(){
    const salesData = [];
    for (let i = 0; i < 12; i += 1) {
      salesData.push({
        x: `Tháng ${i + 1}`,
        y: Math.floor(Math.random() * 1000000000) + 200,
      });
    }
    this.setState({salesData})
  }

  getDataByPaymentMethod(){
    const salesPieData = [
      {
        x: 'Webside',
        y: 4544,
      },
      {
        x: 'Facebook',
        y: 3321,
      },
      {
        x: 'Zalo',
        y: 3113,
      },
      {
        x: 'khác',
        y: 2341,
      }
    ];
    this.setState({salesPieData})
  }

  render() {
    const{salesData, salesPieData} = this.state
    
    return (
      <div className="container text-center">
        <Bar height={200} title="Doanh thu của năm" data={salesData} />
        <div className="row">
          <Pie
            hasLegend
            title="Các nguồn"
            subTitle="Nguồn thu nhập từ các bên"
            total={() => (<span>{salesPieData.reduce((pre, now) => now.y + pre, 0).toLocaleString() + " VND"}</span>)}
            data={salesPieData}
            valueFormat={val => <span>{" - "+val.toLocaleString() + " VND"}</span>}
            height={200}
          />
        </div>
      </div>
    );
  }
}
