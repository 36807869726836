import * as util from '../util'
const instance = util.instance

export function push(obj) {
  return instance
    .post(`publish/notifications/push`, obj)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}