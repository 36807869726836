import React from "react";

export default class index extends React.Component {
    render(){
      return (
        <div style={{fontSize: '13px', color: '#000', fontFamily: '"Open Sans",sans-serif', lineHeight: '18px', maxWidth: '780px', margin: '0 auto'}}><div className="adM">
          </div><div><div className="adM">
            </div><h1 style={{fontSize: '48px', padding: '40px 0 18px', borderBottom: '1px solid #7f8c8d', color: '#ff7c00', lineHeight: '48px', margin: 0}}>XÁC NHẬN ĐƠN HÀNG</h1>
          </div>
          <div>
            <div>
              <p>Chào quý khách: <strong> Son Hoang</strong></p>	
              <p style={{marginTop: '5px'}}>Xin cảm ơn quý khách đã sử dụng dịch vụ của chúng tôi!</p>
              <p style={{marginTop: '5px', color: 'red'}}>Xin quý khách lưu ý: Email này chỉ xác nhận việc đặt vé. Thông tin cụ thể về tình trạng vé, giá vé và chỗ ngồi sẽ được chúng tôi gửi đến quý khách trong thời gian sớm nhất.</p>
            </div>
            <div style={{marginTop: '20px'}}>
              <div style={{backgroundColor: '#2064c8', padding: '10px 15px', color: '#fff', fontWeight: 'bold', fontSize: '18px', textTransform: 'uppercase'}}>
                THÔNG TIN ĐƠN HÀNG
              </div>
              <table style={{marginTop: '12px'}}>
                <tbody><tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>Mã đơn hàng:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold', color: '#2064c8', fontSize: '19px'}}>668385</td>
                  </tr>
                  <tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>Trạng thái:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold'}}>Chờ xác nhận</td>
                  </tr>
                  <tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>Loại vé:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold'}}>Một chiều </td>
                  </tr>
                  <tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>Số lượng hành khách:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold'}}>1</td>
                  </tr>
                  <tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>Hình thức thanh toán:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold'}}>
                      Thanh toán qua cổng thanh toán điện tử
                    </td>
                  </tr>
                  <tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>Tổng giá:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold', color: '#ff7c00', fontSize: '19px'}}>1,277,900.0 VNĐ</td>
                  </tr>
                </tbody></table>
            </div>
            <div style={{marginTop: '20px'}}>
              <div style={{backgroundColor: '#2064c8', padding: '10px 15px', color: '#fff', fontWeight: 'bold', fontSize: '18px', textTransform: 'uppercase'}}>
                THÔNG TIN LIÊN HỆ
              </div>
              <table style={{marginTop: '12px'}}>
                <tbody><tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>Họ tên:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold', color: '#2064c8', fontSize: '19px'}}> Son Hoang</td>
                  </tr>
                  <tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>Điện thoại:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold'}}>+8443212900</td>
                  </tr>
                  <tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>E-mail:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold'}}><a href="mailto:hoangson.0126@gmail.com" target="_blank">hoangson.0126@gmail.com</a></td>
                  </tr>
                  <tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>Địa chỉ:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold'}}>Cầu giấy</td>
                  </tr>
                  <tr>
                    <td style={{padding: '6px 0', minWidth: '160px'}}>Hình thức thanh toán:</td>
                    <td style={{padding: '6px 0', minWidth: '160px', fontWeight: 'bold'}}>Thanh toán qua cổng thanh toán điện tử</td>
                  </tr>
                </tbody></table>
            </div>
            <div style={{marginTop: '20px'}}>
              <div style={{backgroundColor: '#2064c8', padding: '10px 15px', color: '#fff', fontWeight: 'bold', fontSize: '18px', textTransform: 'uppercase'}}>
                Thông tin hành trình
              </div>
              <table style={{width: '100%'}}>
                <tbody><tr>
                    <td style={{padding: '8px 0', textAlign: 'center'}}>Ngày đi</td>
                    <td style={{padding: '8px 0', textAlign: 'center'}}>Từ HAN</td>
                    <td style={{padding: '8px 0', textAlign: 'center'}}>Đến SGN</td>
                    <td style={{padding: '8px 0', textAlign: 'center'}}>Chuyến bay</td>
                    <td style={{padding: '8px 0', textAlign: 'center'}}>Hãng hàng không</td>
                    <td style={{padding: '8px 0', textAlign: 'center'}}>Hạng đặt chỗ</td>
                  </tr>
                  <tr>
                    <td style={{padding: '8px 0', textAlign: 'center', borderTop: '1px solid #7f8c8d', fontWeight: 'bold'}}>2019-05-15</td>
                    <td style={{padding: '8px 0', textAlign: 'center', borderTop: '1px solid #7f8c8d', fontWeight: 'bold'}}>
                      21:15						
                    </td>
                    <td style={{padding: '8px 0', textAlign: 'center', borderTop: '1px solid #7f8c8d', fontWeight: 'bold'}}>
                      23:25
                    </td>
                    <td style={{padding: '8px 0', textAlign: 'center', borderTop: '1px solid #7f8c8d', fontWeight: 'bold'}}>
                      VJ185
                    </td>
                    <td style={{padding: '8px 0', textAlign: 'center', color: '#ff7c00', borderTop: '1px solid #7f8c8d', fontWeight: 'bold'}}>
                      VJ
                    </td>
                    <td style={{padding: '8px 0', textAlign: 'center', borderTop: '1px solid #7f8c8d', fontWeight: 'bold'}}>Eco</td>
                  </tr>
                </tbody></table>
            </div>
            <div style={{marginTop: '20px'}}>
              <div style={{backgroundColor: '#2064c8', padding: '10px 15px', color: '#fff', fontWeight: 'bold', fontSize: '18px', textTransform: 'uppercase'}}>
                THÔNG TIN HÀNH KHÁCH
              </div>
              <div style={{overflow: 'hidden', margin: '10px 0 0px', padding: '5px 0'}}>
                <span style={{fontSize: '18px', display: 'inline-block', marginRight: '40px'}}>1.Anh : Son Hoang</span>
                <b style={{display: 'inline-block'}}>Hành lý chiều đi: 20kg</b>
              </div>
              <h3 style={{color: '#666', fontSize: '20px', fontWeight: 'bold', textTransform: 'uppercase'}}>Điều kiện giá vé chiều đi</h3>
              <table style={{width: '100%', marginBottom: '30px'}}>
                <tbody><tr>
                    <td style={{padding: '5px 0', fontWeight: 'bold', textTransform: 'uppercase', width: '100%', color: '#ff7c00'}} colSpan={2}>Điều kiện hành lý:</td>
                  </tr>
                  <tr>
                    <td style={{padding: '5px 0', color: '#666666', fontWeight: 'bold', minWidth: '150px', width: '150px', paddingRight: '20px'}}>Hành lý xách tay:</td>
                    <td style={{padding: '5px 0'}}>7 kg</td>
                  </tr>
                  <tr>
                    <td style={{padding: '5px 0', color: '#666666', fontWeight: 'bold', minWidth: '150px', width: '150px', paddingRight: '20px'}}>Hành lý ký gửi:</td>
                    <td style={{padding: '5px 0'}}>Có thể mua thêm</td>
                  </tr>
                </tbody></table>
              <table style={{width: '100%', marginBottom: '30px'}}>
                <tbody><tr>
                    <td style={{padding: '5px 0', fontWeight: 'bold', textTransform: 'uppercase', width: '100%', color: '#ff7c00'}} colSpan={2}>Điều kiện vé:</td>
                  </tr>
                  <tr>
                    <td style={{padding: '5px 0', color: '#666666', fontWeight: 'bold', minWidth: '150px', width: '150px', paddingRight: '20px'}}>Thay đổi chuyến bay</td>
                    <td style={{padding: '5px 0'}} />
                  </tr>
                  <tr>
                    <td style={{padding: '5px 0', color: '#666666', fontWeight: 'bold', minWidth: '150px', width: '150px', paddingRight: '20px'}}>Thay đổi hành trình</td>
                    <td style={{padding: '5px 0'}}>Được phép - Thu phí: 400.000 VND cộng chênh lệch giá, Trước 24 giờ so với giờ khởi hành</td>
                  </tr>
                  <tr>
                    <td style={{padding: '5px 0', color: '#666666', fontWeight: 'bold', minWidth: '150px', width: '150px', paddingRight: '20px'}}>Đổi tên hành khách</td>
                    <td style={{padding: '5px 0'}}>
                      Đổi tên hành khách: Được phép - Thu phí: 500.000 VND, Trước 24 giờ so với giờ khởi hành
                    </td>
                  </tr>
                  <tr>
                    <td style={{padding: '5px 0', color: '#666666', fontWeight: 'bold', minWidth: '150px', width: '150px', paddingRight: '20px'}}>Bảo lưu vé</td>
                    <td style={{padding: '5px 0'}} />
                  </tr>
                  <tr>
                    <td style={{padding: '5px 0', color: '#666666', fontWeight: 'bold', minWidth: '150px', width: '150px', paddingRight: '20px'}}>Hoàn vé</td>
                    <td style={{padding: '5px 0'}}>Không được phép</td>
                  </tr>
                  <tr>
                    <td style={{padding: '5px 0', color: '#666666', fontWeight: 'bold', minWidth: '150px', width: '150px', paddingRight: '20px'}}>Thời hạn thay đổi thông tin chuyến bay</td>
                    <td style={{padding: '5px 0'}}>Được phép - Thu phí: 400.000 VND cộng chênh lệch giá, Trước 24 giờ so với giờ khởi hành</td>
                  </tr>
                </tbody></table>
            </div>
            <div style={{paddingBottom: '40px'}}>
              <div className="m_-3197499277959042041container">
                <label className="m_-3197499277959042041note m_-3197499277959042041c7" style={{textTransform: 'uppercase', fontWeight: 'bold', display: 'inline-block', color: '#ff7c00'}}>! LƯU ý:</label>
                <p style={{marginTop: '10px', color: '#666'}}>
                  Quý khách vui lòng kiểm tra lại ngày giờ bay, chặng bay, họ tên chính xác, đồng thời đến làm thủ tục trước 90 phút đối với chặng bay nội địa và 180 phút đối với chặng bay quốc tế.
                  <br />
                  Với mong muốn được phục vụ khách hàng ngày càng chu đáo hơn, Vegiare luôn sẵn sàng tiếp nhận và biết ơn mọi sự góp ý của Quý khách hàng.
                  <br />
                  Vì vậy nếu quý khách có bất cứ điều gì chưa hài lòng về sản phẩm, dịch vụ hay phong cách phục vụ của Vegiare, xin vui lòng phản ánh trực tiếp tới chúng tôi theo số hotline 0904.391.391 vào bất cứ lúc nào. Chúng tôi sẽ nhanh chóng giải quyết và có thông tin phản hồi kịp thời cho mọi vướng mắc hay mọi sự chưa hài lòng của Quý khách hàng.
                  <br />
                  Chúc Quý khách có những chuyến bay thật thoải mái và thượng lộ bình an !
                  <br />
                  Xin chân thành cảm ơn !
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div style={{textAlign: 'center', color: '#666', lineHeight: '10px'}}>
            <p><b style={{fontWeight: 'bold'}}>VéGiáRẻ</b> - Chi phí hợp lý, chuyến đi như ý!</p>
            <p><b>Địa chỉ:</b> Tầng 4 Tòa nhà Kinh Bắc Thăng Long, Số 278 Thụy Khuê, Tây Hồ , Hà Nội.</p>
            <p><b>Điện thoại:</b> (+84) 0243 218 1433      <b>Fax:</b> (+84) 243 200 3907 <b>Hotline:</b> (+84) 0967 11 22 88</p>
            <p><b>Website:</b><a style={{textDecoration: 'none'}} href="https://www.vegiare.com" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.vegiare.com&source=gmail&ust=1558413484111000&usg=AFQjCNHCYPol-qnxUt0K4PaexJUQ5VIxHQ"> https://www.vegiare.com</a>     <b>Email:</b><a style={{textDecoration: 'none'}} href="mailto:datve@vegiare.com" target="_blank"> datve@vegiare.com</a></p><div className="yj6qo" /><div className="adL">
            </div></div><div className="adL">
          </div></div>
      );
    }
}