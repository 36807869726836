import React, {Component} from 'react';
import {Input} from 'antd';


export default class InputNumber extends Component {
    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return {
                value : nextProps.value || ""
            };
        }
        return null;
    }

    constructor(props) {
        super(props);
        const value = props.value || '';
        this.state = {
            value: value,
        };
    }

    handleNumberChange = e => {
        let string = ('' + e.target.value).replace(/\D/g, '');
        this.setState({value : string});
        this.triggerChange(string);
    };

    triggerChange = changedValue => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    };

    render() {
        const state = this.state;
        return (
            <Input
                {...this.props}
                placeholder={this.props.placeholder}
                type="text"
                value={state.value}
                onChange={this.handleNumberChange}
            />
        );
    }
}