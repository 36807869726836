import * as util from '../util'
const instance = util.instance

export function getBanks() {
  return instance
    .get("banks/")
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}
export function createBanks(data) {
    return instance
      .post('/banks/bank',data)
      .then(response => {
        return response.data;
      })
      .catch(util.checkError);
}
export function updateBanks(id,data) {
    return instance
      .put(`/banks/bank/${id}`,data)
      .then(response => {
        return response.data;
      })
      .catch(util.checkError);
}
export function deleteBanks(id) {
    return instance
      .delete(`/banks/bank/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(util.checkError);
}

