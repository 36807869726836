import React from "react";
import "./styles.css";
import * as api from "../../api/customer";
import NumericInput from '../../components/inputtext_number'
import _ from 'lodash'
import { Icon, Button, DatePicker, Modal, Radio } from 'antd';
const confirm = Modal.confirm;
var moment = require('moment');

const dateFormat = 'DD/MM/YYYY';
const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DD'T'HH:mm:ss.SSS`];

const DATE_DEFAULT = "01/01/1990";

export default class customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      customers: [],
      baggageDeparture: "",
      birthday: "",
      baggageReturn: "",
      gender: true,
      phone: "",
      email: "",
      type: "",
      isAdd: true,
      index: 0
    };
  }

  componentDidMount() {
    
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.customers != "undefined") {
      this.setState({customers : nextProps.customers, isAddBook: nextProps.isAddBook});
    }
  }

  showDeleteCustomerConfirm(id, index) {
    confirm({
      title: 'Cảnh báo!',
      content: 'Bạn có chắc chắn muốn xoá khách hàng?',
      okText: 'Có',
      okType: 'danger',
      cancelText: 'Không',
      onOk:()=> {
        this.deleteCustomer(id, index)
      },
      onCancel() {
        
      },
    });
  }

  openModalCustomer = (item, index) => {
    var birthday = DATE_DEFAULT;
    if(item.birthday != null && item.birthday !== ""){
      birthday = item.birthday;
    }

    this.setState({ 
      modalIsOpenCustomer: true,
      firstName: item.firstName,
      lastName: item.lastName,
      baggageDeparture: item.baggageDeparture,
      priceDeparture: item.priceDeparture,
      birthday,
      baggageReturn: item.baggageReturn,
      priceReturn: item.priceReturn,
      gender: item.gender,
      id: item.id,
      phone: item.phone,
      email: item.email,
      type: item.type,
      isAdd: false,
      index
    });
  }

  addCustomer=()=>{
    this.setState({ 
      modalIsOpenCustomer: true,
      firstName: "",
      lastName: "",
      baggageDeparture: "",
      priceDeparture: "",
      birthday: DATE_DEFAULT,
      baggageReturn: "",
      priceReturn:"",
      gender: true,
      phone: "",
      email: "",
      type: "ADT",
      isAdd: true,
    });
  }

  closeModalCustomer = () => {
    this.setState({ modalIsOpenCustomer: false });
  }

  handleChangeFirstName = (event) => {
    this.setState({ firstName: event.target.value });
  };

  handleChangeLastName = (event) => {
    this.setState({ lastName: event.target.value });
  };

  handleChangeBirthday = (date, dateString) => {
    if(date != null)
      this.setState({ birthday: date.format("YYYY-MM-DD'T'HH:mm:ss.SSS") });
  };

  handleChangeDepartureFlight = (value) => {
    this.setState({ baggageDeparture: value });
  };

  handleChangeReturnFlight = (value) => {
    this.setState({ baggageReturn: value });
  };

  handleChangePriceReturn = (value) => {
    this.setState({ priceReturn: value });
  };

  handleChangePriceDeparture = (value) => {
    this.setState({ priceDeparture: value });
  };

  handleChangePhone = (event) => {
    this.setState({ phone: event.target.value });
  };

  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  renderItem(item, index) {
    var birthday = "";
    if(item.birthday != null && item.birthday !== ""){
      birthday = moment(item.birthday, dateFormatList).format(dateFormat)
    }
    return (
      <tr key={item.id} className="row m-0">
        {/* <td className="align-middle col-1">{index+1}</td> */}
        <td className="align-middle col-1">{item.gender ? "Nam" : "Nữ"}</td>
        <td className="align-middle col-3">
          {item.lastName + " " + item.firstName}
        </td>
        <td className="align-middle col-2">{birthday}</td>
        <td className="align-middle col-3">{item.baggageDeparture+"kg - "+ item.priceDeparture+"VND"}</td>
        <td className="align-middle col-3">{item.baggageReturn+"kg - "+ item.priceReturn+"VND"}</td>
        {/* <th className="align-middle col-1">
            <Icon type="edit" onClick={()=>this.openModalCustomer(item, index)}/>
            <br/>
            <Icon type="delete" onClick={() => {this.showDeleteCustomerConfirm(item.id, index)}}/>
          </th> */}
      </tr>
    );
  }

  onChangeGender = e => {
    this.setState({gender: e.target.value});
  };

  onChangeType = e => {
    this.setState({type: e.target.value});
  };

  createCustomer=()=>{
    const {customers, isAddBook, isAdd, id, firstName, lastName, gender, birthday, baggageDeparture, priceDeparture, baggageReturn, priceReturn, phone, email, type, index} = this.state
    const obj = {firstName, lastName, gender, birthday, baggageDeparture, priceDeparture, baggageReturn, priceReturn, phone, email, type}
    if(firstName===""){
      alert("Tên không để trống")
      return;
    }

    if(lastName===""){
      alert("Họ không để trống")
      return;
    }

    if(phone===""){
      alert("Số điện thoại không để trống")
      return;
    }

    if(baggageDeparture===""){
      alert("Hành lý chiều đi không để trống")
      return;
    }

    if(priceDeparture===""){
      alert("Giá hành lý chiều đi không để trống")
      return;
    }

    if(isAddBook){
      if(isAdd){
        customers.push(obj)
        this.setState({customers, modalIsOpenCustomer: false})
        this.props.customersRep(customers)
      }else{
        customers.splice(index, 1, obj)
        this.setState({customers, modalIsOpenCustomer: false})
      }
    }else{
      if(isAdd){
        api.createCustomer(this.props.idBook, obj).then(response => {
          if (response !== undefined) {
            this.props.getBook()
            alert(response.message);
            this.setState({modalIsOpenCustomer: false})
          }
        });
      }else{
        api.editCustomer(id, obj).then(response => {
          if (response !== undefined) {
            this.props.getBook()
            alert(response.message);
            this.setState({modalIsOpenCustomer: false})
          }
        });
      }
    }
  }

  getCustomer=()=>{
    api.getCustomer(this.props.idBook).then(response => {
      if (response !== undefined) {
        this.setState({customers: response, modalIsOpenCustomer: false})
      }
    });
  }

  deleteCustomer=(id, index)=>{
    const {isAddBook, customers} = this.state
    if(isAddBook){
      customers.splice(index, 1);
      this.setState({customers})
    }else{
      api.deleteCustomer(id).then(response => {
        if (response !== undefined) {
          this.props.getBook()
          alert(response.message);
        }
      });
    }
  }

  render() {
    const { firstName, lastName, gender, birthday, baggageDeparture, priceDeparture, baggageReturn, priceReturn, customers, phone, email, type } = this.state;
    return (
      <div>
        <div className="row mt-3 d-flex pl-3 pr-3 align-self-center">
        <h6 >THÔNG TIN HÀNH KHÁCH:</h6>
        {/* <div className="ml-auto">
          <Button type="primary" onClick={this.addCustomer}> Thêm mới </Button>
        </div> */}
        </div>
        <table className="table table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              {/* <th className="col-1">STT</th> */}
              <th className="col-1">GIỚI TÍNH</th>
              <th className="col-3">TÊN</th>
              <th className="col-2">NGÀY SINH</th>
              <th className="col-3">HÀNG LÝ ĐI</th>
              <th className="col-3">HÀNG LÝ VỀ</th>
              {/* <th className="align-middle col-1">HÀNH ĐỘNG</th> */}
            </tr>
          </thead>
          <tbody>
            {customers.map((item, index) => this.renderItem(item, index))}
          </tbody>
        </table>

        <Modal
          visible={this.state.modalIsOpenCustomer}
          confirmLoading={false}
          onCancel={this.closeModalCustomer}
          footer={[
            <Button key="back" onClick={this.closeModalCustomer}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" loading={this.state.loading} onClick={this.createCustomer}>
              Lưu
            </Button>
          ]}
        >
        <div className="row mt-3">
          <div className="col-6">
          <h6>Tên</h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={firstName}
            onChange={this.handleChangeFirstName}
          />

          <h6>Họ</h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={lastName}
            onChange={this.handleChangeLastName}
          />

          <h6>Giới tính:</h6>
          <Radio.Group onChange={this.onChangeGender} value={gender}>
            <Radio value={true}>Nam</Radio>
            <Radio value={false}>Nữ</Radio>
          </Radio.Group>

          <h6 className="mt-3">Email: </h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={email}
            onChange={this.handleChangeEmail}
          />

          <h6>SDT: </h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={phone}
            onChange={this.handleChangePhone}
          />
          </div>

          <div className="col-6">
          <h6 >Hành lý đi(kg):</h6>
          <NumericInput className="form-control mb-3" value={baggageDeparture} onChange={this.handleChangeDepartureFlight} />

          <h6>Giá hành lý đi(VND):</h6>
          <NumericInput className="form-control mb-3" value={priceDeparture} onChange={this.handleChangePriceDeparture} />

          <h6>Hành lý về(kg):</h6>
          <NumericInput className="form-control mb-3" value={baggageReturn} onChange={this.handleChangeReturnFlight} />

          <h6>Giá hành lý về(VND):</h6>
          <NumericInput className="form-control mb-3" value={priceReturn} onChange={this.handleChangePriceReturn} />

          <h6>Loại hành khách:</h6>
          <Radio.Group onChange={this.onChangeType} value={type}>
            <Radio value={"ADT"}>Người lớn</Radio>
            <Radio value={"CHD"}>Trẻ em</Radio>
            <Radio value={"INF"}>Trẻ sơ sinh</Radio>
          </Radio.Group>

          <h6 className="mt-3">Ngày sinh:</h6>
          <DatePicker value={moment(birthday, dateFormatList)} format={dateFormat} onChange={this.handleChangeBirthday}/>
          </div>
          </div>
        </Modal>
      </div>
    );
  }
}
