import * as util from '../util'
const instance = util.instance

export function createCategory(name, slug) {
  var obj = {name, slug}

  return instance
    .post("promotionsCategory/create", obj)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function updateCategory(id, name) {
  var obj = {name}

  return instance
    .put(`promotionsCategory/update/${id}`, obj)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function deleteCategory(id) {
  return instance
    .delete(`promotionsCategory/delete/${id}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getAllCategory() {
  return instance
    .get(`promotionsCategory`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function searchCategory(key,) {
  return instance
    .get(`promotionsCategory/search?key=${key}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getCategoryById(id) {
  return instance
    .get(`promotionsCategory/${id}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}
