import * as util from '../util'
const instance = util.instance

export function countNotification() {
  return instance
    .get("publish/notifyBook")
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function countGroupTicket() {
  return instance
    .get("publish/groupTicket/count")
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}
