import React from "react";
import * as api from "../../api/country";
import { Button } from 'antd';

export default class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subDes: "",
      image: undefined,
      description: ""
    };
  }

  componentDidMount() {
    const { code } = this.props.match.params;
    api.getCountry(code).then(response => {
      if (response !== undefined) {
        var data = response.data
        this.setState({
          id: data.id,
          title: data.name,
          image: data.imageUrl,
          description: data.description,
          subDes: data.subDescription,
        });
      }
    });
  }

  handleChangeSubDes = event => {
    this.setState({ subDes: event.target.value });
  };

  handleChangeDes = event => {
    this.setState({ description: event.target.value });
  };

  submitHTML = () => {
    const { id, image, title, subDes, description } = this.state;

    if (title.trim() === "") {
      alert("Tiêu đề không được bỏ trống!");
      return;
    }

    if (subDes == null || subDes.length < 50) {
      alert("Nội dung quá ngắn!");
      return;
    }

      api
        .updateCountry(
          id,
          typeof image === "string" ? null : image,
          subDes,
          description
        )
        .then(response => {
          if (response !== undefined) {
            alert(response.message);
          }
        });
  };

  selectImages = event => {
    let image = event.target.files.item(0);
    image.name.match(/\.(jpg|jpeg|png|gif)$/);
    this.setState({ image });
  };

  render() {
    const { image, subDes, title, description } = this.state;
    return (
      <div>
        <h3>{title}</h3>
        <h6 className="mt-3">Chọn ảnh:</h6>
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="customFile"
            name="filename"
            onChange={this.selectImages}
          />
          <label className="custom-file-label" htmlFor="customFile">
            Chọn ảnh
          </label>
        </div>
        {image !== undefined ? (
          <img
            className="image mt-3 border border-secondary"
            src={typeof image === "string" || image === null ? image : URL.createObjectURL(image)}
            alt=""
          />
        ) : null}

        <h6 className="mt-3">Mô tả ngắn:</h6>
        <textarea
          rows="2"
          className="form-control mb-3"
          value={subDes}
          onChange={this.handleChangeSubDes}
        />

        <h6 className="mt-3">Mô tả:</h6>
        <textarea
          rows="10"
          className="form-control mb-3"
          value={description}
          onChange={this.handleChangeDes}
        />
        <Button type="primary" className="mt-2" onClick={this.submitHTML}>Lưu</Button>
      </div>
    );
  }
}