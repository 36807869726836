import * as util from '../util'
const instance = util.instance

export function editCustomer(id, obj) {
  return instance
    .put(`customers/${id}`, obj)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function deleteCustomer(id) {
  return instance
    .delete(`customers/${id}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function createCustomer(id, obj) {
  return instance
    .post(`customers/create/${id}`, obj)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getCustomer(idBook) {
  return instance
    .get(`customers?idBook=${idBook}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}