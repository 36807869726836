import React from "react";
import { Select } from 'antd';
import jsonp from 'fetch-jsonp';
import querystring from 'querystring';

import * as api from '../../api/province'

const { Option } = Select;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function fake() {
    api.searchProvinceAll(value).then(response => {
      if (response !== undefined && currentValue === value) {
        const data = [];
        response.data.map((item)=>{
          data.push({
            value: item.airports[0].code,
            text: item.name,
          })
        })
        callback(data);
      }
    });
  }

  timeout = setTimeout(fake, 300);
}

export default class SearchInput extends React.Component {
  state = {
    data: [],
    value: this.props.children,
  };

  handleSearch = value => {
    fetch(value, data => this.setState({ data }));
  };

  handleChange = (value,label) => {
    this.setState({ value });
    this.props.value(value, label.props.children)
  };

  render() {
    const options = this.state.data.map(d => <Option key={d.value}>{d.text}</Option>);
    return (
      <Select
        showSearch
        value={this.state.value}
        placeholder={this.props.placeholder}
        style={this.props.style}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={this.handleSearch}
        onChange={(value, label)=>this.handleChange(value, label)}
        notFoundContent={null}
      >
        {options}
      </Select>
    );
  }
}