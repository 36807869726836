import React from "react";
import "../styles.css";
import * as api from "../../api/user";
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import { Modal, Button, Icon } from 'antd';

const NO_DISABLE = "";
const DISABLE = "disabled-div";

export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      totalPages: 1,
      modalIsOpen: false,
      name: "",
      email: "",
      id: 0,
      password: "",
      confirmPassword: "",
      roles: [],
      role_select: 0,
      loading: false,
      isAdd: true,
      search: "",
      disable: NO_DISABLE
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.getUsers(0);
    api.getRoles().then(response => {
      if (response !== undefined) {
        this.setState({
          roles: response
        });
      }
    });
  }

  search = () =>{
    const {search} = this.state
    const { role } = this.props.match.params;
    api.searchUser(role, 0, search).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: response.pageable.pageNumber + 1,
          totalPages: response.totalPages > 0 ? response.totalPages : 1
        });
      }
    });
  }

  getUsers(page){
    const { role } = this.props.match.params;
    this.setState({role_select: role})
    api.getUsers(role, page).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: response.pageable.pageNumber + 1,
          totalPages: response.totalPages > 0 ? response.totalPages : 1
        });
      }
    });
  }

  openModal() {
    this.setState({ 
      modalIsOpen: true, 
      isAdd: true,
      id: 0,
      name: "",
      email: "",
      disable: NO_DISABLE
    });
  }

  afterOpenModal() {

  }

  handleChangeName = event => {
    this.setState({ name: event.target.value });
  };

  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
  };

  handleChangePassword = event => {
    this.setState({ password: event.target.value });
  };

  handleChangeConfirmPassword = event => {
    this.setState({ confirmPassword: event.target.value });
  };

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleCheck(role_select, event) {
    var checked = event.target.checked;
    if (checked === true) this.setState({ role_select });
  }

  onPageChange = page => {
    this.setState({ page }, () => {
      api.getUsers(this.state.role_select, this.state.page - 1).then(response => {
        if (response !== undefined) {
          this.setState({
            data: response.content,
            page: response.pageable.pageNumber + 1
          });
        }
      });
    });
  };

  createUser = () => {
    const { name, email, password, confirmPassword, role_select, isAdd, id } = this.state;
    if(name.trim() === ""){
      alert("Tên không để trống");
      return;
    }
    if(name.trim().length < 4){
      alert("Tên phải nhiều hơn 4 ký tự");
      return;
    }
    if(email.trim() === ""){
      alert("Email không để trống");
      return;
    }
    if(isAdd === true){
      if(password.trim() === ""){
        alert("Mật khẩu không để trống");
        return;
      }

      if(password.trim().length < 6){
        alert("Mật khẩu ít nhất 6 ký tự");
        return;
      }

      if(confirmPassword.trim() !== password.trim()){
        alert("Xác nhận mật khẩu không không trùng hợp");
        return;
      }

      const obj = { name, email, password, roleId: role_select };
      api.createUser(obj).then(response => {
        if (response !== undefined) {
          this.getUsers(0);
          alert(response.message);
          this.closeModal()
        }
      });
    }else {
      const obj = { name, email, roleId: role_select };
      api.updateUser(obj, id).then(response => {
        if (response !== undefined) {
          this.getUsers(0);
          alert(response.message);
          this.closeModal()
        }
      });
    }
  };

  showItem(item){
    this.setState({
      isAdd: false,
      id: item.id,
      name: item.name,
      email: item.email,
      role_select: item.roles[0].id,
      modalIsOpen: true,
      disable: DISABLE
    })
  }

  renderItem(item, index) {
    // let des = decode(item.description);
    return (
      <tr className="row m-0" key={item.id}>
        <td className="align-middle col-1">{index +1}</td>
        <td className="align-middle col-3">
          <img
            src={require("../../images/ic_male.png")}
            style={{ width: 50, height: 50 }}
            alt=""
          />
        </td>
        <td className="align-middle col-3">{item.name}</td>
        <td className="align-middle col-3">{item.email}</td>
        <td className="align-middle col-2">
            <Icon type="edit" onClick={() => {this.showItem(item);}}/>
        </td>
      </tr>
    );
  }

  searchChange=(event)=>{
    var search = event.target.value
    this.setState({ search });
  }

  renderRole(roles, role_select){
    return(
      <div>
      {roles.map((item, index) => (
        <div className="form-check disabled" key={index}>
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="optradio"
              onChange={event => {
                this.handleCheck(item.id, event);
              }}
              checked={item.id === Number(role_select)}
            />
            {item.nameDisplay}
          </label>
        </div>
        
      ))}
      </div>
    )
  }

  render() {
    const { name, email, password,confirmPassword, roles, role_select, disable, isAdd } = this.state;
    return (
      <div className="container text-center">
        <div className="text-right">
          <input
            className="mr-sm-2"
            type="search"
            placeholder="Tìm kiếm email"
            aria-label="Search"
            value={this.state.search}
            onChange={this.searchChange}
          />
          <Button type="primary" className="mt-2" loading={this.state.loading} onClick={this.search}>Tìm kiếm</Button>

          <Button type="primary" className="ml-5" loading={this.state.loading} onClick={this.openModal}>+ Thêm</Button>
        </div>
        <table className="table mt-2 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-1">STT</th>
              <th className="col-3">Ảnh</th>
              <th className="col-3">Tên</th>
              <th className="col-3">Email</th>
              <th className="col-2">Hành động</th>
            </tr>
          </thead>
          <tbody>{this.state.data.map((item, index) => this.renderItem(item, index))}</tbody>
        </table>
        <div style={{display: this.state.data.length !== 0 ? 'block' : 'none' }}>
          <ReactUltimatePagination
            currentPage={this.state.page}
            totalPages={this.state.totalPages}
            onChange={this.onPageChange}
          />
        </div>
        <Modal
          visible={this.state.modalIsOpen}
          onOk={this.createUser}
          confirmLoading={false}
          onCancel={this.closeModal}
          footer={isAdd ? [
            <Button key="back" onClick={this.closeModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" loading={this.state.loading} onClick={this.createUser}>
              Lưu
            </Button>,
          ] : []}
        >
          <h6>Tên</h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={name}
            onChange={this.handleChangeName}
          />
          <h6>Email</h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={email}
            onChange={this.handleChangeEmail}
          />
          <div className={disable}>
            <h6>Mật khẩu</h6>
            <input
              rows="1"
              type="password"
              className="form-control mb-3"
              value={password}
              onChange={this.handleChangePassword}
            />

            <h6>Xác nhận mật khẩu</h6>
            <input
              rows="1"
              type="password"
              className="form-control mb-3"
              value={confirmPassword}
              onChange={this.handleChangeConfirmPassword}
            />
          </div>
          <h6>Vai trò</h6>
          {this.renderRole(roles, role_select)}
        </Modal>
      </div>
    );
  }
}
