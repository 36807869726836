import React, { Component } from 'react';
import * as api from "../../api/invite";
import { Spin, Modal, Button, Icon, Input, Form, Radio} from 'antd';
import { Link } from "react-router-dom";

class index extends Component {

    state={
        data : [],
        loading:false,
        showModal:false,
        file:null,
        loadingCreate:false,
        buttonAdd:true,
        id:null,
        value:null
    }

    constructor(props){
        super(props);
    }

    getInvitations(){
        this.setState({loading:true})
        api.getInvitations().then(response => {
          if (response !== undefined) {
            this.setState({
              data: response,
              loading: false
            });
          }
        });
    }

    componentDidMount(){
        this.getInvitations()
    }

    showBanks = () => {
        if (this.state.data.length === 0) {
            return null
        } else {
            return(
                this.state.data.map((value,index) => (
                    <tr  key={index}>
                        <th scope="row">{index+1}</th>
                        <td>{value.fullName}</td>
                        <td>{value.phone}</td>
                        <td>{value.gender === true ? 'Nam': 'Nữ'}</td>
                        <td>{value.profit}</td>
                        <td>{value.discount}</td>
                        <td>{value.note}</td>
                        <td>
                            <Link to={"/invite/book/" + value.phone}>
                                <Icon type="edit" style={{color:"black"}}/>
                            </Link>
                        </td>
                    </tr>
                ))
            )
        }
    }

    getFile = (file) => {
		this.setState({file:file.target.files[0]})	
	}

    showModal = (id,value) => {
        if (!id) {
            this.setState({
                buttonAdd:true,
            })
        } else {
            this.setState({
                buttonAdd:false,
                id:id,
                value:value
            })
        }
        this.setState({showModal:true})
    }

    handleCancel = () => {
        
		this.setState({ 
            showModal: false 
        });
    };
    
    handleSubmit = (e,id) => {
        e.preventDefault();
        if (!id) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    var data = new FormData()
                    // data.append("accountName",values["accountName"])
                    // data.append("accountNumber",values["accountNumber"])
                    // data.append("bankName",values["bankName"])
                    // data.append("address",values["address"])
                    // data.append("image",this.state.file)
                    // this.setState({loadingCreate:true})
                    api.createInvitation(
                        data
                    ).then(res=>{
                        this.setState({
                            showModal:false,
                            loadingCreate:false
                        })
                        alert(res.message)
                        if (res.message === "Thành công!") {
                            this.getInvitations()
                        }
                    })
                }
            });
        } else {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    // var data = new FormData()
                    // data.append("accountName",values["accountName"])
                    // data.append("accountNumber",values["accountNumber"])
                    // data.append("bankName",values["bankName"])
                    // data.append("address",values["address"])
                    // this.setState({loadingCreate:true})
                    // api.updateBanks(
                    //     id,data
                    // ).then(res=>{
                    //     this.setState({
                    //         showModal:false,
                    //         loadingCreate:false
                    //     })
                    //     alert(res.message)
                    //     if (res.message === "Thành công!") {
                    //         this.getInvitations()
                    //     }
                    // })
                }
            });
            
        }
    };
    
    deleteBanks = (id) => {
        if (window.confirm("Bạn có muốn xóa ngân hàng này không ?")) { 
            // api.deleteBanks(
            //     id
            // ).then(res=>{
            //     alert(res.message)
            //     if (res.message === "Thành công!") {
            //         this.getInvitations()
            //     }
            // })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
		const radioStyle = {
            height: '30px',
            lineHeight: '30px',
        };
        return (
            this.state.loading 
            ?   <div style={{height: 800, display: 'flex'}}>
                    <div style={{margin: 'auto'}}>
                        <Spin size="large"/>
                    </div>
                </div>
            :
            <div className="container ">
                <div type="text-left mb-2">
                    {/* <Button type="primary text-left mb-2" onClick={()=>this.showModal()}>Thêm tài khoản ngân hàng</Button> */}
                </div>
                <table className="table table-hover border text-center">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">Họ và Tên</th>
                            <th scope="col">Sdt</th>
                            <th scope="col">Giới tính</th>
                            <th scope="col">lợi nhuận</th>
                            <th scope="col">Khách hàng</th>
                            <th scope="col">Ghi chú</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.showBanks()}
                    </tbody>
                </table>
                <Modal
					visible={this.state.showModal}
					title="Thêm tài khoản ngân hàng"
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							Đóng
						</Button>,
                        this.state.buttonAdd 
                        ?
						<Button key="submit" htmlType="submit" loading={this.state.loadingCreate} type="primary" onClick={this.handleSubmit}>
							Thêm
						</Button>
                        :
                        <Button key="submit" htmlType="submit" loading={this.state.loadingCreate} type="primary" onClick={(e)=>this.handleSubmit(e,this.state.id)}>
							Cập nhật
						</Button>
					]}
					>
					<Form  className="login-form pl-4">
						<div className="row">
							<Form.Item className="col-6 pr-3 mb-2">
								<span >Tên công ty</span>
								{getFieldDecorator('accountName', {
                                    initialValue:this.state.value === null ? " ": this.state.value.accountName,
									rules: [{ required: true, message: 'Nhập tên công ty' }],
								})(
									<Input
										placeholder="Tên công ty..."
									/>,
								)}
							</Form.Item>
							<Form.Item className="col-6 pl-3 mb-2">
                                <span >Số tài khoản</span>
                                {getFieldDecorator('accountNumber', {
                                    initialValue:this.state.value === null ? " ": this.state.value.accountNumber,
                                    rules: [{ required: true, message: 'Nhập số tài khoản' }],
                                })(
                                    <Input
                                        placeholder="Số tài khoản..."
                                    />,
                                )}
							</Form.Item>	
						</div>
						<div className="row">
							<Form.Item className="col-6 pr-3 mb-2">
								<span >Địa chỉ</span>
								{getFieldDecorator('address', {
                                    initialValue:this.state.value === null ? " ": this.state.value.address,
									rules: [{ required: true, message: 'Nhập địa chỉ' }],
								})(
									<Input
										placeholder="Địa chỉ..."
									/>,
								)}
							</Form.Item>
							<Form.Item className="col-6 pl-3 mb-2">
                                <span >Tên ngân hàng</span>
								{getFieldDecorator('bankName', {
                                    initialValue:this.state.value === null ? " ": this.state.value.bankName,
									rules: [{ required: true, message: 'Nhập tên ngân hàng' }],
								})(
									<Input
										placeholder="Tên ngân hàng..."
									/>,
								)}
							</Form.Item>
						</div>
                        {
                            this.state.buttonAdd 
                            ?<div className="row">
                                <Form.Item className="col-6 mb-2 pr-3">
                                    <span >Ảnh đại diện</span><br/>
                                    {getFieldDecorator('image', {
                                        rules: [{ required: true, message: 'Chọn ảnh ngân hàng' }],
                                    })(
                                        <Input 
                                            onChange={(file)=>this.getFile(file)}
                                            className="mt-1"
                                            type="file"
                                        />,)}
                                </Form.Item>
						    </div>
                            :null
                        }
						
					</Form>
				</Modal>
            </div>
        );
    }
}

export default Form.create()(index);