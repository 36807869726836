import * as util from '../util'
const instance = util.instance

export default function login(email, password) {
  const data = { email, password };
  return instance
    .post("auth/signin", data)
    .then(response => {
      var data = response.data
      util.setAuthHeader(data.data != null ? data.data.accessToken : '')
      return response.data;
    })
    .catch(util.checkError);
}
