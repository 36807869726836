import React from "react";
import { Modal, Button, Input, Form,  } from "antd";
import { MdSend } from "react-icons/md";
import "antd/dist/antd.css";
import "./styles.css";
import * as apiNotification from "../../api/notification";
const { TextArea } = Input;
class Notification extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpenNotify: false,
		};
	}

	componentDidMount() {

	}

	sendNotify = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				var data = {title: values["title"], description: values["description"], titleEn: values["titleEn"], descriptionEn: values["descriptionEn"]}
				apiNotification.push(data).then(res=>{
					alert(res.message)
					this.setState({
						modalIsOpenNotify: false,
					});
				})
			}
		});
	};

	
	render() {
		const { getFieldDecorator } = this.props.form;
		const { title, description,  titleEn, descriptionEn} = this.state;
		return (
			<div>
				<Modal
					visible={this.state.modalIsOpenNotify}
					onCancel={()=>{this.setState({modalIsOpenNotify: false})}}
					width={600}
					footer={[
					<Button key="submit" className="mr-2" onClick={(e)=>this.sendNotify(e)}>
						Gửi thông báo
					</Button>,
					<Button key="submit" onClick={()=>{this.setState({modalIsOpenNotify: false})}}>
						Huỷ
					</Button>
					]}
				>
					<Form className="row">
						<div className="mt-3 col-6">
							<Form.Item className="mb-2">
								<span >Tiêu đề tiếng việt</span><br/>
								{getFieldDecorator('title', {
									initialValue: title,
									rules: [{ required: true, message: 'Nhập tiêu đề của bạn' }],
								})(
									<Input/>,
								)}
							</Form.Item>
							<Form.Item className=" mb-2">
								<span >Nội dung tiếng việt</span><br/>
								{getFieldDecorator('description', {
									initialValue: description,
									rules: [{ required: true, message: 'Nhập nội dung của bạn' }],
								})(
									<TextArea rows={4}/>,
								)}
							</Form.Item>
						</div>
						<div className="mt-3 col-6">
							<Form.Item className="mb-2">
								<span >Tiêu đề tiếng anh</span><br/>
								{getFieldDecorator('titleEn', {
									initialValue: titleEn,
									rules: [{ required: true, message: 'Nhập tiêu đề tiếng anh của bạn' }],
								})(
									<Input/>,
								)}
							</Form.Item>
							<Form.Item className="mb-2">
								<span >Nội dung tiếng anh</span><br/>
								{getFieldDecorator('descriptionEn', {
									initialValue: descriptionEn,
									rules: [{ required: true, message: 'Nhập nội dung tiếng anh của bạn' }],
								})(
									<TextArea rows={4}/>,
								)}
							</Form.Item>
						</div>
					</Form>
				</Modal>

				<MdSend className="size pt-2" color="white" style={{ height: 30, width: 30}} onClick={()=>{this.setState({ modalIsOpenNotify: true });}}/>

					
			</div>
		);
	}
}
export default Form.create()(Notification);
