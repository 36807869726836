import React from "react";
import * as api from "../../api/banner";
import SelectImage from "../../components/select_Image"
import _ from 'lodash'
import { Modal, Button, Icon } from 'antd';
const confirm = Modal.confirm;

export default class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataShow: [],
      name: "",
      image: undefined,
      modalIsOpen: false,
      isAdd: true,
      id: 0,
      search: '',
      loading: false
    };
  }

  componentDidMount() {
    this.getBanner()
  }

  getBanner(){
    api.getBanner().then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.data,
          dataShow: response.data,
          loading: false
        });
      }
    });
  }

  showDeleteConfirm(id) {
    confirm({
      title: 'Cảnh báo!',
      content: 'Bạn có chắc chắn muốn xoá?',
      okText: 'Có',
      okType: 'danger',
      cancelText: 'Không',
      onOk:()=> {
        this.deleteBanner(id);
      },
      onCancel() {
        
      },
    });
  }

  deleteBanner = id => {
    api.deleteBanner(id).then(response => {
      if (response !== undefined) {
        alert(response.message);
        this.getBanner()
      }
    });
  };

  getBannerById = id => {
    let item = _.find(this.state.data, {id});
    this.setState({ id, name: item.name, image: item.image.orginallink, isAdd: false, modalIsOpen: true });
  };

  createBanner = () => {
    this.setState({ loading: true });
    const {id, image, name, isAdd} = this.state
    if(name===""){
      alert("Bạn chưa nhập tên")
      return;
    }
    console.log(typeof image)
    if(typeof image === "string" || image === undefined){
      alert("Bạn chọn lại ảnh")
      return;
    }
    if(isAdd){
      api.createBanner(image, name).then(response => {
        if (response !== undefined) {
          alert(response.message);
          this.getBanner()
        }
      });
    }else {
      api.updateBanner(id, image, name).then(response => {
        if (response !== undefined) {
          alert(response.message);
          this.getBanner()
        }
      });
    }
    this.setState({ modalIsOpen: false, name: '', image: undefined });
  }

  renderItem(item, index) {
    return (
      <tr key={item.id} className="row m-0">
        <td className="align-middle col-2">{index + 1}</td>
        <td className="align-middle col-3">{item.name}</td>
        <td className="align-middle col-4">
          <img
            className="img-thumbnail"
            src={item.image.thumblink}
            alt=""
            width="100"
            height="50"
          />
        </td>
        {/* <td className="align-middle col-2">{item.priority}</td> */}
        <td className="align-middle col-3">
          <Icon type="edit" onClick={() => {this.getBannerById(item.id);}}/>
          <br/>
          <Icon type="delete" onClick={() => {this.showDeleteConfirm(item.id)}}/>
        </td>
      </tr>
    );
  }

  handleChangeName =(event)=>{
    this.setState({ name: event.target.value });
  }

  returnImage = (image) => {
    this.setState({image})
  }

  openModal = () => {
    this.setState({ modalIsOpen: true, name: '', image: undefined, isAdd: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  searchBanner=(event)=>{
    var search = event.target.value
    
    var dataShow = this.state.data
    dataShow =_.filter(dataShow,function(item){
    return item.name.toUpperCase().indexOf(search.toUpperCase())>-1;
    });
    this.setState({ search, dataShow });
  }

  render() {
    const {name, image} = this.state
    return (
      <div className="container text-center">
        <div className="text-right">
          <input
            className="mr-sm-2"
            type="search"
            placeholder="Tìm kiếm"
            aria-label="Search"
            value={this.state.search}
            onChange={this.searchBanner}
          />
          <Button type="primary" className="ml-5" onClick={this.openModal}>+ Thêm</Button>
        </div>
        <table className="table mt-2 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-2">STT</th>
              <th className="col-3">Tên</th>
              <th className="col-4">Ảnh</th>
              {/* <th className="col-2">Ưu tiên</th> */}
              <th className="col-3">Hành động</th>
            </tr>
          </thead>
          <tbody>{this.state.dataShow.map((item, index) => this.renderItem(item, index))}</tbody>
        </table>
        <Modal
          visible={this.state.modalIsOpen}
          onOk={this.createBanner}
          confirmLoading={false}
          onCancel={this.closeModal}
          footer={[
            <Button key="back" onClick={this.closeModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.createBanner}>
              Lưu
            </Button>,
          ]}
        >
          <h6>Tên</h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={name}
            onChange={this.handleChangeName}
          />
          <SelectImage image={image} returnImage={this.returnImage}/>
        </Modal>
      </div>
    );
  }
}
