import React from "react";
import * as api from "../../api/book_airline";
import "./styles.css";
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import { Button, Checkbox } from 'antd';
var moment = require('moment');


export default class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      totalPages: 1,
      staffId: 0,
      users: [],
      valueType: null, 
      status: null,
    };
  }

  componentDidMount() {
    this.getBooks()
  }

  getBooks(){
    api.getCurrentBook(0).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: response.content.length === 0 ? response.pageable.pageNumber : response.pageable.pageNumber + 1,
          totalPages: response.totalPages
        });
      }
    });
  }

  onPageChange = page => {
    this.setState({ page }, () => {
      api.getCurrentBook(this.state.page - 1).then(response => {
        if (response !== undefined) {
          this.setState({
            data: response.content,
            page: response.pageable.pageNumber +1
          });
        }
      });
    });
  };

  onChangeCheckbox=(e, item, index)=> {
    var data = this.state.data
    var item1 = data[index]
    item1.cskh = e.target.checked
    this.setState({data})
  }

  renderItem(item, index) {
    return (
      <tr key={item.id} className="row m-0">
        <td className="align-middle col-2">{item.contact.lastName + ' ' +item.contact.firstName}</td>
        <td className="align-middle col-3">{item.contact.email}</td>
        <td className="align-middle col-3">{item.contact.phone}</td>
        <th className="align-middle col-3">{`${item.departureAirportCode} - ${item.destinationAirportCode} (${moment(item.departureDate).format('HH:mm MM/DD/YYYY')})`}</th>
        <td className="align-middle col-1">
          <Checkbox checked={item.cskh} onChange={(e)=>{this.onChangeCheckbox(e, item, index)}}/>
        </td>
      </tr>
    );
  }

  saveStatus = () =>{
    var data = this.state.data;
    api.updateBookStatus(data).then(response => {
      if (response !== undefined) {
        alert("Cập nhật trạng thái thành công")
      }
    });
  }

  render() {
    return (
      <div className="container text-center">
      <div className="text-right">
        <Button type="primary" onClick={this.saveStatus}>lưu</Button>
        </div>

        <table className="table mt-3 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-2">Tên</th>
              <th className="col-3">Email</th>
              <th className="col-3">SDT</th>
              <th className="col-3">Chặng bay</th>
              <th className="col-1"></th>
            </tr>
          </thead>
          <tbody>{this.state.data.map((item, index) => this.renderItem(item, index))}</tbody>
        </table>
        <div style={{display: this.state.data.length !== 0 ? 'block' : 'none' }}>
          <ReactUltimatePagination
            currentPage={this.state.page}
            totalPages={this.state.totalPages}
            onChange={this.onPageChange}
          />
        </div>
      </div>
    );
  }
}
