import React, { Component } from 'react';
import * as api from "../../api/banks";
import { Spin, Modal, Button, Icon, Input, Form, Tabs} from 'antd';
const { TabPane } = Tabs;
var moment = require('moment');
const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DDTHH:mm:ss.SSSZ`];

class index extends Component {

    state={
        data : [],
        loading:false,
        showModal:false,
        file:null,
        loadingCreate:false,
        buttonAdd:true,
        id:null,
        value:null
    }

    constructor(props){
        super(props);
    }

    getBanner(){
        this.setState({loading:true})
        api.getBanks().then(response => {
          if (response !== undefined) {
            this.setState({
              data: response,
              loading: false
            });
          }
        });
    }

    componentDidMount(){
        this.getBanner()
    }

    showBanks = (note) => {
      const list = JSON.parse(note)
      console.log("list")
      console.log(list)
      if (list === null) {
          return null
      } else {
          return(
            list.map((value,index) => (
                  <tr  key={index}>
                      <td>{value.date}</td>
                      <td>{value.content}</td>
                      <td>{value.amount.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                  </tr>
              ))
          )
      }
    }

    render() {
        return (
            <div className="container ">
                <Tabs>
                  {this.state.data.map((value,index) => (
                    <TabPane tab={value.bankName} key={index}>
                      <h6>
                      <span>Thời gian update: </span>
                      <span>{moment(value.updatedAt, dateFormatList[1]).format('HH:mm DD/MM/YYYY')}</span>
                      </h6>
                      <table className="table table-hover border text-center">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Ngày</th>
                                <th scope="col">Nội dung</th>
                                <th scope="col">Số tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.showBanks(value.note)}
                        </tbody>
                    </table>
                    </TabPane>
                  ))}
                </Tabs>
            </div>
        );
    }
}

export default Form.create()(index);