import * as util from '../util'
const instance = util.instance

export function getInvitations() {
  return instance
    .get("invitation")
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getInvitationByPhone(phone) {
  return instance
    .get(`invitationByPhone?phone=${phone}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function createInvitation(data) {
  return instance
    .post('invitation',data)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function withdrawal(obj){
  return instance
    .post(`invite/withdrawal`, obj)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function invitationByUser() {
  return instance
    .get(`invitationByUser`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

