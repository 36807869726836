import * as util from '../util'
const instance = util.instance

export function updateCountry(id, image, sub, description) {
  const data = new FormData();
  if(image != null){
    data.append("image", image);
  }
  data.append("subDescription", sub);
  data.append("description", description);

  return instance
    .post(`countries/${id}`, data)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getAllCountries(page) {
  return instance
    .get(`countries/${page}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function searchCounties(key, page) {
  return instance
    .get(`countries/search?page=${page}&&key=${key}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getCountry(code) {
  return instance
    .get(`countries/code/${code}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}