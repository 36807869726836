import * as util from '../util'
const instance = util.instance

export function update(id, obj) {
  console.log(id)
  return instance
    .put(`delay/update/${id}`, obj)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}