import React from "react";
import * as api from "../../api/province";
const NORTHERN = 0;
const CENTRAL = 1;
const SOUTH = 2;

export default class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subDes: "",
      description: "",
      image: undefined,
      region: NORTHERN
    };
  }

  componentDidMount() {
    const { code } = this.props.match.params;
    api.getProvince(code).then(response => {
      if (response !== undefined) {
        var data = response.data
        this.setState({
          code: data.id,
          title: data.name,
          image: data.imageUrl,
          description: data.description,
          subDes: data.subDescription,
          region: data.region
        });
      }
    });
  }

  handleCheck(region, event){
    var checked = event.target.checked;
    if(checked === true)
      this.setState({region})
  }

  handleChangeSubDes = event => {
    this.setState({ subDes: event.target.value });
  };

  handleChangeDes = event => {
    this.setState({ description: event.target.value });
  };

  submitHTML = () => {
    const { code, image, title, subDes, description, region} = this.state;
    if (title.trim() === "") {
      alert("Tiêu đề không được bỏ trống!");
      return;
    }

    if (subDes.length < 50) {
      alert("Nội dung quá ngắn!");
      return;
    }

      api
        .editProvince(
          code,
          typeof image === "string" ? null : image,
          subDes,
          description,
          region
        )
        .then(response => {
          if (response !== undefined) {
            alert(response.message);
          }
        });
  };

  selectImages = event => {
    let image = event.target.files.item(0);
    image.name.match(/\.(jpg|jpeg|png|gif)$/);
    this.setState({ image });
  };

  render() {
    const { image, subDes, description, title } = this.state;
    return (
      <div>
        <h3>{title}</h3>
        <h6 className="mt-3">Vùng miền:</h6>
        <div className="form-check">
          <label className="form-check-label">
            <input type="radio" className="form-check-input" name="optradio" onChange={event => {this.handleCheck(NORTHERN, event);}} checked={this.state.region === NORTHERN} />Miền Bắc
          </label>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <input type="radio" className="form-check-input" name="optradio" onChange={event => {this.handleCheck(CENTRAL, event);}} checked={this.state.region === CENTRAL} />Miền Trung
          </label>
        </div>
        <div className="form-check disabled">
          <label className="form-check-label">
            <input type="radio" className="form-check-input" name="optradio" onChange={event => {this.handleCheck(SOUTH, event);}} checked={this.state.region === SOUTH} />Miền Nam
          </label>
        </div>
        <h6 className="mt-3">Chọn ảnh:</h6>
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="customFile"
            name="filename"
            onChange={this.selectImages}
          />
          <label className="custom-file-label" htmlFor="customFile">
            Chọn ảnh
          </label>
        </div>
        {image !== undefined ? (
          <img
            className="image mt-3 border border-secondary"
            src={typeof image === "string" || image === null ? image : URL.createObjectURL(image)}
            alt=""
          />
        ) : null}

        <h6 className="mt-3">Mô tả ngắn:</h6>
        <textarea
          rows="2"
          className="form-control mb-3"
          value={subDes}
          onChange={this.handleChangeSubDes}
        />

        <h6 className="mt-3">Mô tả:</h6>
        <textarea
          rows="10"
          className="form-control mb-3"
          value={description}
          onChange={this.handleChangeDes}
        />

        <button
          type="button"
          className="btn btn-success mt-3"
          onClick={this.submitHTML}
        >
          Submit
        </button>
      </div>
    );
  }
}