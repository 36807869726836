import React from "react";
import * as api from "../../api/book_airline";
import * as apiInvite from "../../api/invite";
import "./styles.css";
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import { Link } from "react-router-dom";
import { Select, Icon, Button, Modal, Input, Form, DatePicker, Row, Col} from 'antd';
const {RangePicker } = DatePicker;
const confirm = Modal.confirm;
const { Option } = Select;
var moment = require('moment');
const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DDTHH:mm:ss.SSSZ`];
const START_DATE = "2020-01-01T06:01:41.989Z"

class Book extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      totalPages: 1,
      users: [],
      modalIsOpen: false,
      airlineCode: "VN",
      total: 0,
      overview: {},
      agent: {
        dateWithdrawal: null
      }
    };
  }

  componentDidMount() {
    const { phone } = this.props.match.params;
    var values = {page: 0, status: 'STATUS_WAIT_HANDLE', phone}
    this.searchBook(values);
    this.getInvite();
  }

  getInvite(){
    const { phone } = this.props.match.params;
    apiInvite.getInvitationByPhone(phone).then(response => {
      if (response !== undefined) {
        this.setState({agent: response.data})

        var obj = {
          startDate: response.data.dateWithdrawal !== null ? moment(response.data.dateWithdrawal, dateFormatList) : moment(START_DATE, dateFormatList),
          endDate: moment().format(dateFormatList[1]),
          phone
        }
        this.getProfit(obj);
      }
    });
  }

  getProfit(obj){
    api.profit(obj).then(response => {
      if (response !== undefined) {
        this.setState({
          overview: response.data
        });
      }
    });
  }

  onPageChange = page => {
    this.setState({ page }, () => {
      this.props.form.validateFields((err, values) => {
          values.page = this.state.page - 1;
          const { phone } = this.props.match.params;
          values.phone = phone
          this.searchBook(values);
      });
    });
  };

  renderItem(item) {
    const STATUS_WAIT_HANDLE="Chờ xử lý"
    const STATUS_PAYMENT ="Đã thanh toán"
    const STATUS_DEPARTURE ="Xuất chiều đi"
    const STATUS_RETURN ="Xuất chiều về"
    const STATUS_CANCEL = "Huỷ"
    const STATUS_DONE = "Hoàn thành"

    var reasons = item.note ? JSON.parse(item.note).reasons : []
    var price = `${item.totalPrice.toLocaleString(navigator.language, { minimumFractionDigits: 0 })} VND`
    var status = "Chờ xác nhận"
    if(item.status === "STATUS_WAIT_HANDLE"){
      status = STATUS_WAIT_HANDLE
    }else if(item.status === "STATUS_PAYMENT"){
      status = STATUS_PAYMENT
    } else if(item.status === "STATUS_DEPARTURE"){
      status = STATUS_DEPARTURE
    } else if(item.status === "STATUS_RETURN"){
      status = STATUS_RETURN
    }else if(item.status === "STATUS_CANCEL"){
      status = STATUS_CANCEL
    } else if(item.status === "STATUS_DONE"){
      status = STATUS_DONE
    } else status = STATUS_CANCEL
    var departureCode = <div>{item.departureCode}</div>
    var returnCode = <div>{item.returnCode}</div>
    return (
      <tr key={item.id} className="row m-0">
        <td className="align-middle col-1">{moment(item.createdAt, dateFormatList[1]).format('HH:mm')}<br></br>{moment(item.createdAt, dateFormatList[1]).format('DD/MM/YYYY')}</td>
        <td className="align-middle col-1">
          <div>
            {item.code}
          </div></td>
        <td className="align-middle col-2">{item.itineraryType === 1 ? departureCode: <div>{departureCode}<br></br> {returnCode}</div>}</td>
        <td className="align-middle col-1">{item.itineraryType === 1 ? 'Một chiều' : 'Khứ hồi'}</td>
        <td className="align-middle col-2">{item.contact.lastName + ' ' +item.contact.firstName} <br></br> {item.contact.phone}</td>
        <td className="align-middle col-1">{price}</td>
        <td className="align-middle col-1">{`${this.getPriceOrigan(item).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} VND`}</td>
        <td className="align-middle col-1">{item.departureAirportCode+'-'+item.destinationAirportCode}</td>
        <td className="align-middle col-1">{status}</td>
        <td className="align-middle col-1">
          <Link to={"/book/edit/" + item.code}>
            <Icon type="edit" style={{color:"black"}}/>
          </Link>
        </td>
      </tr>
    );
  }

  getPriceOrigan(data){
    var note = JSON.parse(data.note)
    return note.invite.profit * data.itineraryType;
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.page = 0;
        
        values.startDate = values.time[0].format(dateFormatList[1])
        values.endDate = values.time[1].format(dateFormatList[1])
        const { phone } = this.props.match.params;
        values.phone = phone
        this.searchBook(values);

        var obj = {
          startDate: values.startDate,
          endDate: values.endDate,
          page: 0
        }
        this.getBookscomplete(obj);
        this.getProfit(obj);
      }
    });
  };

  searchBook(values){
    api.searchBook1(values).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: values.page === 0 && response.content.length === 0 ? response.pageable.pageNumber : response.pageable.pageNumber + 1,
          totalPages: response.totalPages
        });
      }
    });
  }

  withdrawalBook = (values)=>{
    apiInvite.withdrawal(values).then(response => {
      if (response !== undefined) {
        this.getInvite();      
        alert(response.message);
      }
    });
  }

  returnTicket=(e)=>{
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var obj = {code: values["code"], airlineCode: this.state.airlineCode}
        api.returnTicket(obj).then(response => {
          if (response !== undefined) {
            alert(response.message);
          }
        });
      }
    });
  }

  handleChange=(e)=> {
    this.props.form.setFieldsValue({status: e})
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.page = 0;
        
        values.startDate = values.time[0].format(dateFormatList[1])
        values.endDate = values.time[1].format(dateFormatList[1])
        const { phone } = this.props.match.params;
        values.phone = phone
        this.searchBook(values);

        var obj = {
          startDate: values.startDate,
          endDate: values.endDate,
          page: 0
        }
        this.getBookscomplete(obj);
        this.getProfit(obj);
      }
    });
  }

  withdrawalHandler=(e)=> {
    var startDate = this.props.form.getFieldValue('time')[0].format(dateFormatList[1])
    var endDate = this.props.form.getFieldValue('time')[1].format(dateFormatList[1])
    var phone = this.props.match.params.phone;
    var obj = {startDate, endDate, phone, status: null}
    this.withdrawalBook(obj);
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const { users, overview, total, agent} = this.state;
    return (
      <div className="container text-center from-search" style={{overflowX: 'auto'}}>
        <div className="text-left mt-2">
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col span={9}>
              <Form.Item label="Thời gian">
                {getFieldDecorator('time', {
                  rules: [{ required: false }],
                  initialValue: [ agent.dateWithdrawal !== null ? moment(agent.dateWithdrawal, dateFormatList) : moment(START_DATE, dateFormatList), moment(new Date())]
                })(
                <RangePicker 
                  className="w-100"
                  format="DD/MM/YYYY"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5} className="pl-2">
              <Form.Item label="Trạng thái">
                {getFieldDecorator('status', {
                  rules: [{ required: false }],
                  initialValue: "STATUS_WAIT_HANDLE",
                })(
                  <Select className="w-100" onChange={this.handleChange}>
                    <Option value={null}>Tất cả</Option>
                    <Option value="STATUS_WAIT_HANDLE">Chờ xử lý</Option>
                    <Option value="STATUS_PAYMENT">Đã thanh toán</Option>
                    <Option value="STATUS_DEPARTURE">Xuất chiều đi</Option>
                    <Option value="STATUS_RETURN">Xuất chiều về</Option>
                    <Option value="STATUS_DONE">Hoàn thành</Option>
                    <Option value="STATUS_CANCEL">Huỷ</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={5} className="pl-2">
              <Form.Item label="Nội dung">
                {getFieldDecorator('key', {
                  rules: [{ required: false }],
                })(
                  <Input
                    className="w-100"
                    type="search"
                    placeholder="Tìm kiếm theo mã đơn hàng"
                    aria-label="Search"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5} className="pl-2"> 
              <Button type="primary" className="w-100" style={{marginTop: 27}} onClick={this.handleSubmit}>Tìm kiếm</Button>
            </Col>
          </Row>
          </Form>
        </div>
        <div className="row mt-1 ml-1 mr-1">
          <div className="p-1" style={{width: '20%'}}>
            <div className="p-2 bg-info rounded text-white w-100">
              <h6 style={{color: 'white'}}>Đơn hàng thành công</h6>
              <p>{Number(overview.totalOrder).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
            </div>
          </div>

          {/* <div className="p-1" style={{width: '20%'}}>
            <div className="p-2 bg-success rounded text-white w-100">
              <h6 style={{color: 'white'}}>Lợi nhuận</h6>
              <p>{Number(overview.profit).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} đ</p>
            </div>
          </div> */}
          <div className="p-1" style={{width: '40%'}}>

          </div>
          {/* <div className="p-1" style={{width: '20%'}}>
            <Button type="primary" className="w-100" style={{marginTop: 27}} onClick={this.withdrawalHandler}>Rút tiền</Button>
          </div> */}
        </div>
        <table className="table mt-1 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-1">Ngày đặt</th>
              <th className="col-1">Mã đơn hàng</th>
              <th className="col-2">Code</th>
              <th className="col-1">Loại vé</th>
              <th className="col-2">Tên</th>
              <th className="col-1">Tổng tiền</th>
              <th className="col-1">Lợi nhuận</th>
              <th className="col-1">Hành trình</th>
              <th className="col-1">Status</th>
              <th className="col-1">Xử lý</th>
            </tr>
          </thead>
          <tbody>{this.state.data.map(item => this.renderItem(item))}</tbody>
        </table>
        <div style={{display: this.state.data.length !== 0 ? 'block' : 'none' }}>
          <ReactUltimatePagination
            currentPage={this.state.page}
            totalPages={this.state.totalPages}
            onChange={this.onPageChange}
          />
        </div>
      </div>
    );
  }
}
export default Form.create()(Book);