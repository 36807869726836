import React from "react";
import { Modal, Button, Icon, Input, Form, Radio, DatePicker } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import "./styles.css";
import * as api from "../../api/publish";
import * as apiUpdateUser from "../../api/user";
import { MdGroup } from "react-icons/md";
import InputNumber from "../inputtext_number"
import Notification from './Notification'
import Cookies from 'js-cookie'

var moment = require('moment');
const RadioGroup = Radio.Group;
class index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: false,
			modalIsOpenNotify: false,
			number: 0,
			numberGroups: 0,
			file:null,
			visible:false
		};

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	componentDidMount() {
		api.countNotification().then(response => {
			if (response !== undefined) {
				this.setState({
				number: response.count
				});
			}
		});
		api.countGroupTicket().then(response => {
			if (response !== undefined) {
				this.setState({
				numberGroups: response.count
				});
			}
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				var data = new FormData()
				data.append("name",values["username"])
				data.append("gender",values["gender"])
				data.append("birthday",values["birthday"])
				data.append("phone",values["phone"])
				data.append("address",values["address"])
				data.append("image",this.state.file)
				
				apiUpdateUser.updateInfo(
					data
				).then(res=>{
					alert(res.message)
					if (res.message === "Thành công") {
						var data = JSON.parse(Cookies.get('user'));
						data.user =res.data
						// localStorage.setItem('user', JSON.stringify(data))
						this.setState({visible:false})
					}
					
				})
			}
		});
	};

	getFile = (file) => {
		this.setState({file:file.target.files[0]})
		
	}

	showUpdate = () => {
		this.setState({
			visible:true,
			modalIsOpen:false
		})
	}
	handleCancel = () => {
		this.setState({ visible: false });
	};


	render() {
		const { getFieldDecorator } = this.props.form;
		const { number, numberGroups} = this.state;
		const radioStyle = {
            height: '30px',
            lineHeight: '30px',
        };
		var data = Cookies.get('user');
		var user = null;
		if(data != '' && data != undefined && data != null) user = JSON.parse(data);
		if (user != null) {
		return (
			<div>
				<Modal
					visible={this.state.modalIsOpen}
					onOk={this.createUser}
					confirmLoading={false}
					onCancel={this.closeModal}
					width={300}
					footer={[
					// <Button key="submit" className="mr-2" onClick={()=>this.showUpdate()}>
					// 	Cập nhật thông tin
					// </Button>,
					<Link to={"/login"}>
						<Button key="submit">Đăng xuất</Button>
					</Link>
					]}
				>
					<div className="mt-3">
						<img
							src={user === null || user.user.image =='' || user.user.image == null ?require("../../images/ic_male.png"):user.user.image}
							style={{
							width: 80,
							height: 80,
							display: "block",
							margin: "auto"
							}}
							alt=""
						/>
						<p className="mt-3">Tên: {user != null ? user.user.name : ""}</p>
						<p>Email: {user != null ? user.user.email : ""}</p>
						<p>Phone: {user != null ? user.user.phone : ""}</p>
						<p>Địa chỉ: {user != null ? user.user.address : ""}</p>
					</div>
				</Modal>
				<Modal
					visible={this.state.visible}
					title="Cập nhật thông tin"
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							Đóng
						</Button>,
						<Button  htmlType="submit" type="primary" onClick={this.handleSubmit}>
							Cập nhập
						</Button>,
					]}
					>
					<Form  className="login-form pl-4">
						<div className="row">
							<Form.Item className="col-6 pr-3 mb-2">
								<span >Họ và tên</span>
								{getFieldDecorator('username', {
									initialValue: user != null ? user.user.name : "",
									rules: [{ required: true, message: 'Nhập họ tên của bạn' }],
								})(
									<Input
										placeholder="Họ và tên..."
									/>,
								)}
							</Form.Item>
							<Form.Item className="col-6 pl-3 mb-2">
								<span >Giới tính</span><br/>
								{getFieldDecorator('gender', {
									initialValue:user.user.gender,
									rules: [{ required: true, message: 'Nhập giới tính của bạn' }],
								})(
									<RadioGroup >
										<Radio className="mr-5" style={radioStyle}
												value={true}>
											Nam
										</Radio>
										<Radio style={radioStyle}
												value={false}>
											Nữ
										</Radio>
									</RadioGroup>
								)}
							</Form.Item>	
						</div>
						<div className="row">
							<Form.Item className="col-6 pr-3 mb-2">
								<span >Địa chỉ</span>
								{getFieldDecorator('address', {
									initialValue: user != null ? user.user.address : "",
									rules: [{ required: true, message: 'Nhập địa chỉ của bạn' }],
								})(
									<Input
										placeholder="Địa chỉ..."
									/>,
								)}
							</Form.Item>
							<Form.Item className="col-6 pl-3 mb-2">
								<span >Ngày sinh</span>
								{getFieldDecorator('birthday', {
									initialValue: user != null ? moment(user.user.birthday) : "",
									rules: [{ required: true, message: 'Nhập ngày sinh của bạn' }],
								})(<DatePicker />)}
							</Form.Item>
						</div>
						<div className="row">
							<Form.Item className="col-6 pr-3 mb-2">
								<span >Số điện thoại</span>
								{getFieldDecorator('phone', {
									initialValue: user != null ? user.user.phone : "",
									rules: [{ required: true, message: 'Nhập số điện thoại của bạn' }],
								})(
									<InputNumber
										placeholder="Số điện thoại..."
									/>,
								)}
							</Form.Item>
							<Form.Item className="col-6 pl-3 mb-2 pr-3">
								<span >Ảnh đại diện</span><br/>
								{getFieldDecorator('image', {
									rules: [{ required: true, message: 'Chọn ảnh đại diện của bạn' }],
								})(
									<Input 
										onChange={(file)=>this.getFile(file)}
										className="mt-1"
										type="file"
									/>,)}
							</Form.Item>
						</div>
					</Form>
				</Modal>
				<div className="row">

					{/* <Notification/>

					<Link className="size pt-2" to="/book/group">
						<div>
							<p className="circle">{numberGroups}</p>
							<MdGroup color="white" style={{ height: 30, width: 30}} />
						</div>
					</Link>

					<Link className="size pt-2" to="/book/notification">
						<div>
							<p className="circle">{number}</p>
							<Icon
							type="bell"
							theme="filled"
							style={{ color: "white", fontSize: "20px" }}
							/>
						</div>
					</Link> */}

					<img
						style={{borderRadius: "50px"}}
						src={user.user.image =='' || user.user.image == null ?require("../../images/ic_male.png"):user.user.image}
						className="size"
						alt=""
						onClick={this.openModal}
					/>
				</div>
			</div>
		);
		} else {
			return null;	
		}
	}
}
export default Form.create()(index);
