import * as util from '../util'
const instance = util.instance

export function getSuggest(page) {
  return instance
    .get(`publish/suggest?page=${page}`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function createSuggest(title, description, promotions) {
    var ids = "";
    promotions.map((item, index)=>
      ids += index === 0 ? item.id : ", " + item.id
    )
    var obj = {title, description, ids}
    return instance
      .post(`publish/suggest/create`, obj)
      .then(response => {
        return response.data;
      }).catch(util.checkError);
  }

export function getSuggestById(id) {
  return instance
    .get(`publish/suggest/${id}`)
    .then(response => {
      return response.data.data;
    }).catch(util.checkError);
}

export function deleteSuggest(id) {
  return instance
    .delete(`suggest/${id}`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function editSuggest(id, title, description, promotions) {
  var ids = "";
  promotions.map((item, index)=>
    ids += index === 0 ? item.id : ", " + item.id
  )
  var obj = {title, description, ids}
  return instance
    .post(`publish/suggest/${id}`, obj)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}