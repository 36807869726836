import React from "react";
import * as api from "../../api/blog";
import "./styles.css";
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import { Link } from "react-router-dom";
import { Modal, Icon, Button, Checkbox } from 'antd';
import CONST from "../../components/Const";
import Cookies from 'js-cookie'

const confirm = Modal.confirm;
var moment = require('moment');

const dateFormat = 'hh:mm-DD/MM/YYYY';
const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DDTHH:mm:ss.SSSZ`];

export default class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      totalPages: 0,
      loading: false,
      search: '',
    };
  }

  componentDidMount() {
    var page = 0
    api.getAllPromotion(page, this.props.isCreate).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: page === 0 && response.content.length === 0 ? response.pageable.pageNumber : response.pageable.pageNumber + 1,
          totalPages: response.totalPages
        });
      }
    });
  }

  onPageChange = page => {
    this.setState({ page }, () => {
      api.getAllPromotion(this.state.page - 1, this.props.isCreate).then(response => {
        if (response !== undefined) {
          this.setState({
            data: response.content,
            page: response.pageable.pageNumber +1
          });
        }
      });
    });
  };

  showDeleteConfirm(id) {
    confirm({
      title: 'Cảnh báo!',
      content: 'Bạn có chắc chắn muốn xoá?',
      okText: 'Có',
      okType: 'danger',
      cancelText: 'Không',
      onOk:()=> {
        this.deletePromotion(id);
      },
      onCancel() {
        
      },
    });
  }

  deletePromotion = id => {
    api.deletePromotion(id).then(response => {
      if (response !== undefined) {
        this.componentDidMount()
        alert(response.message);
      }
    });
  };

  onChangeCheckbox = (e, index)=>{
    var data = this.state.data
    data[index].unPublish = !e.target.checked
    this.setState({data})
  }

  renderItem(item, index) {
    var date = this.props.isCreate ? item.createdAt : item.datePublish
    if(this.props.isCreate){ 
    return (
      <tr key={item.id} className="row m-0">
        <th className="col-1"><Checkbox onChange={(e)=>this.onChangeCheckbox(e, index)}/></th>
        <th className="align-middle col-2" scope="row">
          {moment(date, dateFormatList).format(dateFormat)}
        </th>
        <td className="align-middle col-2">
          <img
            className="img-thumbnail"
            src={item.image.thumblink}
            alt=""
            width="100"
            height="50"
          />
        </td>
        <td className="align-middle col-3">{item.title}</td>
        <td className="align-middle col-3">{item.subDescription}</td>
        <td className="align-middle col-1">
          <Link to={"/blog/edit/" + item.id}>
            <Icon type="edit" style={{color:"black"}}/>
          </Link>
          <br/>
          <Icon type="delete" onClick={() => {this.showDeleteConfirm(item.id)}}/>
        </td>
      </tr>
    ); }
    return (
      <tr key={item.id} className="row m-0">
        <th className="align-middle col-2" scope="row">
          {moment(date, dateFormatList).format(dateFormat)}
        </th>
        <td className="align-middle col-3">
          <img
            className="img-thumbnail"
            src={item.image.thumblink}
            alt=""
            width="100"
            height="50"
          />
        </td>
        <td className="align-middle col-3">{item.title}</td>
        <td className="align-middle col-3">{item.subDescription}</td>
        <td className="align-middle col-1">
          <Link to={"/blog/edit/" + item.id}>
            <Icon type="edit" style={{color:"black"}}/>
          </Link>
          <br/>
          <Icon type="delete" onClick={() => {this.showDeleteConfirm(item.id)}}/>
        </td>
      </tr>
    );
  }

  searchBlog = ()=>{
    const {search} = this.state
    this.setState({ loading: true }, () => {
      api.searchPromotion(search, 0, this.props.isCreate).then(response => {
        if (response !== undefined) {
          this.setState({
            data: response.content,
            page: response.content.length === 0 ? response.pageable.pageNumber : response.pageable.pageNumber + 1,
            totalPages: response.totalPages,
            loading: false
          });
        }
      }).catch = (error)=>{
        this.setState({
          loading: false
        });
      };
    });
  }

  publishBlog=()=>{
    const{data} = this.state;
    var idPromotions = [];
    data.map((item) => { if(item.unPublish === false){idPromotions.push(item.id)}})
    if(idPromotions.length > 0){
      api.publishPromotion(idPromotions).then(response => {
        if (response !== undefined) {
          this.componentDidMount()
          alert(response.message);
        }
      });
    }
  }

  searchChange=(event)=>{
    var search = event.target.value
    this.setState({ search });
  }

  render() {
    const{isCreate} = this.props;

    var isAdmin = false;
    var data = Cookies.get('user');
    var role = CONST.ROLE_ADMIN;
    if (data !== null && data !== "") {
      var user = JSON.parse(data);
      role = user.user.roles[0].name;
    }
    if (role === CONST.ROLE_ADMIN) {
      isAdmin = true;
    }

    return (
      <div className="container text-center">
        <div className="text-right">
          <input
            className="mr-sm-2"
            type="search"
            placeholder="Tìm kiếm"
            aria-label="Search"
            value={this.state.search}
            onChange={this.searchChange}
          />
          <Button type="primary" loading={this.state.loading} onClick={this.searchBlog}>Tìm kiếm</Button>

          {isCreate && isAdmin ? <Button className="ml-5" type="primary" onClick={this.publishBlog}>Duyệt bài viết</Button> : null}

          {isCreate ?
            <Link className="ml-5" to="/blog/add">
              <Button type="primary" >+ Thêm</Button>
            </Link> : null
          }

        </div>
        <table className="table mt-2 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              {isCreate ? <th className="col-1"></th>: null}
              <th className="col-2">{isCreate ? "Ngày tạo": "Ngày duyệt"}</th>
              {isCreate ? <th className="col-2">Ảnh</th>: <th className="col-3">Ảnh</th>}
              <th className="col-3">Tiêu đề</th>
              <th className="col-3">Nội dung</th>
              <th className="col-1">Hành động</th>
            </tr>
          </thead>
          <tbody>{this.state.data.map((item,index) => this.renderItem(item, index))}</tbody>
        </table>
        <div className={this.state.data.length === 0 ? "none" : ""}>
          <ReactUltimatePagination
            currentPage={this.state.page}
            totalPages={this.state.totalPages}
            onChange={this.onPageChange}
          />
        </div>
      </div>
    );
  }
}
