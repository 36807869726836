import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from 'js-cookie'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Cookies.get("user") === "" || Cookies.get("user") === null || Cookies.get("user") === undefined ? (
        <Redirect to="/login" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default PrivateRoute;
