import React from "react";
import "./styles.css";
import * as api from "../../api/blog";
import { Button } from 'antd';


// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
// Require Font Awesome.
import "font-awesome/css/font-awesome.css";
import FroalaEditor from "react-froala-wysiwyg";
import $ from "jquery";
window.$ = $;

var _ = require("lodash");
var decode = require("decode-html");
var htmlEncode = require("htmlencode").htmlEncode;
var slugify = require('slugify')

const config = {
  placeholderText: "",
  fullPage: true, // for email message
  useClasses: false, // for email message
  fontFamilySelection: true,
  imageEditButtons: [
    "imageReplace",
    "imageAlign",
    "imageCaption",
    "imageRemove",
    "|",
    "imageLink",
    "linkOpen",
    "linkEdit",
    "linkRemove",
    "-",
    "imageDisplay",
    "imageStyle",
    "imageAlt",
    "imageSize"
  ],
  zIndex: 999999,
  imageUpload: false,
  events: {
    "froalaEditor.image.beforeUpload": function(e, editor, files) {
      if (files.length) {
        // Create a File Reader.
        var reader = new FileReader();

        // Set the reader to insert images when they are loaded.
        reader.onload = function(e) {
          var result = e.target.result;
          editor.image.insert(result, null, null, editor.image.get());
        };

        // Read image as base64.
        reader.readAsDataURL(files[0]);
      }

      editor.popups.hideAll();

      // Stop default upload chain.
      return false;
    }
  }
};

export default class Add extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subDes: "",
      image: undefined,
      category: [],
      model: "",
      slug: "",
      category_select: 1
    };
  }

  componentDidMount() {
    this.getAllCategory();
  }

  getAllCategory() {
    api.getAllCategory().then(response => {
      if (response !== undefined) {
        this.setState({
          category: response
        });
        if (this.props.match !== undefined) {
          const { id } = this.props.match.params;
          this.getPromotionById(id, response);
        }
      }
    });
  }

  getPromotionById(id, category1) {
    api.getPromotionById(id).then(response => {
      if (response !== undefined) {
        var promotion = response.data;
        let des = decode(promotion.description);

        var childCategory = _.filter(category1, { id: promotion.category.id });
        var index = _.findIndex(category1, childCategory[0]);
        category1[index].isCheck = true;
        var category = category1;

        this.setState({
          title: promotion.title,
          model: des,
          image: promotion.image.orginallink,
          subDes: promotion.subDescription,
          category,
          slug: promotion.slugPromotion,
          category_select: promotion.category.id
        });
      }
    });
  }

  handleChangeTitle = event => {
    this.setState({ title: event.target.value });
  };

  handleCheck(category_select, event) {
    var checked = event.target.checked;
    if (checked === true) this.setState({ category_select });
  }

  handleChangeSubDes = event => {
    this.setState({ subDes: event.target.value });
  };

  submitHTML = () => {
    const { model, image, title, subDes, category_select } = this.state;
    var content = model;

    if (title.trim() === "") {
      alert("Tiêu đề không được bỏ trống!");
      return;
    }

    if ( image === undefined) {
      alert("Bạn chưa chọn ảnh");
      return;
    }

    if (subDes.length < 10) {
      alert("Mô tả quá ngắn!");
      return;
    }

    if (content.length < 50) {
      alert("Nội dung quá ngắn!");
      return;
    }

    if (this.props.match === undefined) {
      api
        .promotion(
          typeof image === "string" ? null : image,
          title,
          htmlEncode(content),
          subDes,
          category_select,
          slugify(title)
        )
        .then(response => {
          if (response !== undefined) {
            alert(response.message);
          }
        });
    } else {
      const { id } = this.props.match.params;
      api
        .updatePromotion(
          id,
          typeof image !== "string" ? image : null,
          title,
          htmlEncode(content),
          subDes,
          category_select
        )
        .then(response => {
          if (response !== undefined) {
            alert(response.message);
          }
        });
    }
  };

  selectImages = event => {
    let image = event.target.files.item(0);
    image.name.match(/\.(jpg|jpeg|png|gif)$/);
    this.setState({ image });
  };

  renderCategory = (item, index) => {
    const {category_select} = this.state
    return (
      <div className="form-check disabled" key={index}>
        <label className="form-check-label">
          <input
            type="radio"
            className="form-check-input"
            name={item.id}
            onChange={event => {
              this.handleCheck(item.id, event);
            }}
            checked={category_select === item.id}
          />
          {item.name}
        </label>
      </div>
    );
  };

  handleModelChange = model => {
    this.setState({
      model: model
    });
  };

  changeAlias(alias) {
    var str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
    str = str.replace(/đ/g,"d");
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
    str = str.replace(/ + /g," ");
    str = str.trim(); 
    return str;
}

  render() {
    const { image, category, slug, title } = this.state;
    var slugTitle = this.changeAlias(title);
    return (
      <div>
        <h3>BÀI VIẾT</h3>
        <h6 className="mt-3">Tiêu đề:</h6>
        <input
          type="text"
          className="form-control"
          value={title}
          onChange={this.handleChangeTitle}
        />

        <h6 className="mt-3">Slug:</h6>
        <p>{this.props.match !== undefined ? slug : slugify(slugTitle)}</p>

        <h6 className="mt-3">Chủ đề:</h6>
        {category.map((item, index) => this.renderCategory(item, index))}

        <h6 className="mt-3">Chọn ảnh:</h6>
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="customFile"
            name="filename"
            onChange={this.selectImages}
          />
          <label className="custom-file-label" htmlFor="customFile">
            Chọn ảnh
          </label>
        </div>
        {image !== undefined ? (
          <img
            className="image mt-3 border border-secondary"
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt=""
          />
        ) : null}

        <h6 className="mt-3">Mô tả ngắn:</h6>
        <input
          type="text"
          className="form-control mb-3"
          value={this.state.subDes}
          onChange={this.handleChangeSubDes}
        />

        <FroalaEditor
          tag="textarea"
          config={config}
          model={this.state.model}
          onModelChange={this.handleModelChange}
        />
        <Button type="primary" className="mt-2" loading={this.state.loading} onClick={this.submitHTML}>Lưu</Button>
      </div>
    );
  }
}