import React from "react";

export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: undefined
    };
  }

  componentDidMount() {
    this.setState({ image: this.props.image });
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.image != "undefined") {
      this.setState({ image: nextProps.image });
    }
  }

  selectImages = event => {
    let image = event.target.files.item(0);
    if(image != null){
      image.name.match(/\.(jpg|jpeg|png|gif)$/);
      this.setState({ image });

      this.props.returnImage(
        typeof image === "string" ? null : image,
        image.name
      );
    }
  };

  render() {
    const { image } = this.state;
    console.log(typeof image);
    return (
      <div>
        <h6 className="mt-3">Chọn ảnh:</h6>
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="customFile"
            name="filename"
            onChange={this.selectImages}
          />
          <label className="custom-file-label" htmlFor="customFile">
            Chọn ảnh
          </label>
        </div>
        {image !== undefined ? (
          <img
            className="mw-100 mt-3 border border-secondary"
            src={
              typeof image === "string" || image === null
                ? image
                : URL.createObjectURL(image)
            }
            alt=""
          />
        ) : null}
      </div>
    );
  }
}
