import * as util from '../util'
const instance = util.instance

export function getUsers(role, page) {
  return instance
    .get(`users?page=${page}&&role=${role}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getUser(id) {
  return instance
    .get(`users/${id}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function deleteUser(id) {
  return instance
    .delete(`users/${id}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getRoles() {
  return instance
    .get(`auth/roles`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function createUser(obj) {
  return instance
    .post(`users/create`, obj)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function updateUser(obj, id) {
  return instance
    .post(`users/${id}`, obj)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getAsign() {
  return instance
    .get(`users/addBook`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function searchUser(role, page, key) {
  var obj = {"key": key, "roleId": role}
  return instance
    .post(`users/search?page=${page}`, obj)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function updateInfo(data) {
  return instance
    .post('/users/update',data)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}