import React, { Component } from 'react';
import * as api from "../../api/airline";
import { Spin, Modal, Button, Icon, Input, Form, Radio} from 'antd';
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import { Link } from "react-router-dom";
class Airlines extends Component {

    state={
        page:1,
        totalPages:1,
        sizePages:20,
        key:"",
        data : [],
        loading:false,
        showModal:false,
        file:null,
        loadingCreate:false,
        value:null,
        result:null
    }

    constructor(props){
        super(props);
    }

    getAirline(page,key){
        this.setState({loading:true})
        api.getAirline(page-1,key).then(response => {
          if (response !== undefined) {
            this.setState({
                data: response.content,
                loading: false,
                totalPages:response.totalPages,
                sizePages:response.size
            });
          }
        });
    }

    onPageChange = (page) => {
        this.setState({page:page})
        this.getAirline(page,this.state.key)
    }

    componentDidMount(){
        this.getAirline(this.state.page,this.state.key)
    }

    showAirline = () => {
        if (this.state.data.length === 0) {
            return <tr><td colSpan="6" >Không có kết quả tìm kiếm cho bạn.</td></tr>
        } else {
            return(
                this.state.data.map((value,index) => (
                    <tr  key={index}>
                        <th scope="row">{(this.state.page-1)*this.state.sizePages +index +1}</th>
                        <td>{value.name}</td>
                        <td>{value.code}</td>
                        <td>{value.description}</td>
                        <td><div style={{width:"80px",margin:"auto"}}><img style={{maxHeight:"100%",maxWidth:"100%"}} src={value.imageUrl}/></div></td>
                        <td>
                            <Link style={{color:"#212529"}} to={"/airline/detail/" + value.name}><Icon className="mr-2" type="info-circle" style={{cursor: "pointer"}} /></Link>
                            <Icon type="edit" style={{cursor: "pointer"}} onClick={()=>this.showModal(value)} />
                        </td>
                    </tr>
                ))
            )
        }
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    getFile = (file) => {
		this.setState({
            file:file.target.files[0]
        })
        if (file.target.files[0]) {
            this.getBase64(file.target.files[0], (result) => {
                this.setState({result})
            })	
        }
        
	}

    showModal = (value) => {
        this.setState({
            showModal:true,
            value:value
        })
        this.props.form.resetFields()
    }

    handleCancel = () => {
        
		this.setState({ 
            showModal: false ,
            result:null
        });
    };
    
    handleSubmit = (e,id) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                var data = new FormData()
                if (this.state.file !== null) {
                    data.append("image",this.state.file)
                }
                data.append("description",values["description"])
                this.setState({loadingCreate:true})
                api.updateAirline(
                    id,data
                ).then(res=>{
                    this.setState({
                        showModal:false,
                        loadingCreate:false
                    })
                    alert(res.message)
                    if (res.message === "Thành công!") {
                        this.getAirline(this.state.page,this.state.key)
                    }
                }).catch(error=>{
                    alert(error.response.message)
                })
            }
        });
            
    };

    searchText = (e) => {
        api.getAirline(0,e.target.value).then(response => {
            if (response.content.length > 0) {
              this.setState({
                  data: response.content,
                  totalPages:response.totalPages,
                  sizePages:response.size
              });
            }else{
                this.setState({
                    data: response.content,
                    totalPages:0
                })
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            this.state.loading 
            ?   <div style={{height: 800, display: 'flex'}}>
                    <div style={{margin: 'auto'}}>
                        <Spin size="large"/>
                    </div>
                </div>
            :
            <div className="container ">
                <div className="row text-right mb-3 mr-2 d-flex flex-row-reverse">
                    <Input className="col-3" onChange={(e)=>this.searchText(e)} placeholder="Nhập tên sân bay" />
                    <span className="col-3 mt-auto mb-auto">Tìm kiếm</span>
                </div>
                <table className="table table-hover border text-center">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">Tên hãng hàng không</th>
                            <th scope="col">Mã hãng</th>
                            <th scope="col">Mô tả</th>
                            <th scope="col">Ảnh</th>
                            <th scope="col">Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.showAirline()}
                    </tbody>
                </table>
                {
                    this.state.totalPages === 0 || this.state.totalPages === 1
                    ? 
                        null
                    :
                        <ReactUltimatePagination
                            currentPage={this.state.page}
                            totalPages={this.state.totalPages}
                            onChange={this.onPageChange}
                        />
                }
                
                <Modal
					visible={this.state.showModal}
					title="Cập nhật thông tin hãng hàng không"
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							Đóng
						</Button>,
                        <Button key="submit" htmlType="submit" loading={this.state.loadingCreate} type="primary" onClick={(e)=>this.handleSubmit(e,this.state.value.id)}>
							Cập nhật
						</Button>
					]}
					>
					<Form  className="login-form pl-4">
						<div className="row">
							<Form.Item className="col-12 pr-3 mb-2">
								<span >Mô tả</span>
								{getFieldDecorator('description', {
                                    initialValue:this.state.value === null ? " ": this.state.value.description,
									rules: [{ required: true, message: 'Vui lòng nhập mô tả' }],
								})(
									<Input
										placeholder="Nhập mô tả ..."
									/>,
								)}
							</Form.Item>	
						</div>
                        <div className="row">
                            <Form.Item className="col-6 mb-2 pr-3">
                                <span >Ảnh đại diện</span><br/>
                                {(<Input 
                                    onChange={(file)=>this.getFile(file)}
                                    className="mt-1"
                                    type="file"
                                />)}
                            </Form.Item>
                            <Form.Item className="col-6 mb-2 pr-3">
                                <span >Ảnh đang dùng</span><br/>
                                <div className={this.state.result !== null ? "d-none": ''} style={{width:"80px"}}><img style={{maxHeight:"100%",maxWidth:"100%"}} src={this.state.value === null ? " ":this.state.value.imageUrl}/></div>
                                <div style={{width:"80px"}}><img style={{maxHeight:"100%",maxWidth:"100%"}} src={this.state.result === null ? " ":this.state.result}/></div>
                            </Form.Item>
                        </div>
					</Form>
				</Modal>
            </div>
        );
    }
}

export default Form.create()(Airlines);