import React from "react";
import Airline from '../bookAirline';
import Coach from '../bookCoach';
import { Tabs } from 'antd';
const { TabPane } = Tabs;


export default class index extends React.Component {
  render() {
    return (
      <Tabs defaultActiveKey="1">
        <TabPane tab="Vé máy bay" key="1">
          <Airline/>
        </TabPane>
        <TabPane tab="Vé xe" key="2">
          <Coach/>
        </TabPane>
      </Tabs>
    );
  }
}
