import React from "react";
import * as api from "../../api/category_blog";
import "./styles.css";
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import { Link } from "react-router-dom";
import { Modal, Icon, Button, Checkbox } from 'antd';
import _ from 'lodash'
var slugify = require('slugify')
const confirm = Modal.confirm;
var moment = require('moment');

const dateFormat = 'hh:mm-DD/MM/YYYY';
const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DDTHH:mm:ss.SSSZ`];

export default class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataShow: [],
      search: '',
      modalIsOpen: false, 
      isAdd: false,
      name: "",
      slug: ""
    };
  }

  componentDidMount() {
    this.getAllCategory();
  }

  getAllCategory(){
    api.getAllCategory().then(response => {
      if (response !== undefined) {
        this.setState({
          data: response,
          dataShow: response
        });
      }
    });
  }

  openModalAdd = () => {
    this.setState({ 
      modalIsOpen: true, 
      isAdd: true,
      name: ""
    });
  }

  openModalEdit = (item) => {
    this.setState({ 
      modalIsOpen: true, 
      isAdd: false,
      name: item.name,
      slug: item.slug,
      id: item.id
    });
  }
  
  showDeleteConfirm(id) {
    confirm({
      title: 'Cảnh báo!',
      content: 'Bạn có chắc chắn muốn xoá?',
      okText: 'Có',
      okType: 'danger',
      cancelText: 'Không',
      onOk:()=> {
        this.deletePromotion(id);
      },
      onCancel() {
        
      },
    });
  }

  deletePromotion = id => {
    api.deleteCategory(id).then(response => {
      if (response !== undefined) {
        this.getAllCategory()
        alert(response.message);
      }
    });
  };

  renderItem(item, index) {
    return (
      <tr key={item.id} className="row m-0">
        <th className="col-1">{index + 1}</th>
        <th className="align-middle col-3" scope="row">
          {moment(item.createdAt, dateFormatList).format(dateFormat)}
        </th>
        <td className="align-middle col-3">{item.name}</td>
        <td className="align-middle col-3">{item.slug}</td>
        <td className="align-middle col-2">

          <Icon type="edit" onClick={() => {this.openModalEdit(item)}}/>
          <br/>
          <Icon type="delete" onClick={() => {this.showDeleteConfirm(item.id)}}/>
        </td>
      </tr>
    );
  }

  searchBlog = ()=>{
    var search = this.state.search
    
    var dataShow = this.state.data
    dataShow =_.filter(dataShow,function(item){
    return item.name.indexOf(search)>-1;
    });
    this.setState({ search, dataShow });
  }

  searchChange=(event)=>{
    var search = event.target.value
    this.setState({ search });
  }

  handleChangeName = event => {
    this.setState({ name: event.target.value });
  };

  closeModal =()=> {
    this.setState({ modalIsOpen: false });
  }

  changeAlias(alias) {
    var str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
    str = str.replace(/đ/g,"d");
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
    str = str.replace(/ + /g," ");
    str = str.trim(); 
    return str;
  } 

  createCategory = () => {
    const { name, slug, isAdd, id } = this.state;
    if(name.trim() === ""){
      alert("Tên không để trống");
      return;
    }

    if(isAdd){
      var slugTitle = this.changeAlias(name);
      api.createCategory(name, slugify(slugTitle)).then(response => {
        if (response !== undefined) {
          alert(response.message);
          this.closeModal()
          this.getAllCategory();
        }
      });
    }else {
      api.updateCategory(id, name).then(response => {
        if (response !== undefined) {
          alert(response.message);
          this.closeModal()
          this.getAllCategory();
        }
      });
    }
  };

  render() {
    const{name, slug, isAdd }= this.state
    var slugTitle = this.changeAlias(name);
    return (
      <div className="container text-center">
        <div className="text-right">
          <input
            className="mr-sm-2"
            type="search"
            placeholder="Tìm kiếm"
            aria-label="Search"
            value={this.state.search}
            onChange={this.searchChange}
          />
          <Button type="primary" onClick={this.searchBlog}>Tìm kiếm</Button>

          <Button type="primary" className="ml-3" onClick={this.openModalAdd}>+ Thêm</Button>
        </div>
        <table className="table mt-2 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-1">STT</th>
              <th className="col-3">Ngày tạo</th>
              <th className="col-3">Tiêu đề</th>
              <th className="col-3">Slug</th>
              <th className="col-2">Hành động</th>
            </tr>
          </thead>
          <tbody>{this.state.dataShow.map((item,index) => this.renderItem(item, index))}</tbody>
        </table>

        <Modal
          visible={this.state.modalIsOpen}
          onOk={this.createUser}
          confirmLoading={false}
          onCancel={this.closeModal}
          footer={[
            <Button key="back" onClick={this.closeModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.createCategory}>
              Lưu
            </Button>,
          ]}
        >
          <h6>Tên</h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={name}
            onChange={this.handleChangeName}
          />

          <h6 className="mt-3">Slug:</h6>
          <p>{isAdd ? slugify(slugTitle) : slug}</p>
        </Modal>
      
      </div>
    );
  }
}
