import React, { Component } from 'react';
import * as api from "../../api/airline";
import { Spin, Modal, Button, Icon, Input, Form, Typography} from 'antd';
import { Link } from "react-router-dom";
const { Text } = Typography;
const { TextArea } = Input;
class AirlinesDetail extends Component {

    state={
        page:1,
        key:this.props.match.params.name,
        data : [],
        loading:false,
        showModal:false,
        loadingCreate:false,
        value:null,
    }

    constructor(props){
        super(props);
    }

    getAirline(page,key){
        this.setState({loading:true})
        api.getAirline(page-1,key).then(response => {
          if (response !== undefined) {
            this.setState({
                data: response.content,
                loading: false,
            });
          }
        });
    }

    componentDidMount(){
        this.getAirline(this.state.page,this.state.key)
    }

    showAirline = () => {
        if (this.state.data.length === 0) {
            return <tr><td colSpan="6" >Không có kết quả tìm kiếm cho bạn.</td></tr>
        } else {
            return(
                this.state.data[0].classTickets.map((value,index) => (
                    <tr  key={index}>
                        <th scope="row">{index+1}</th>
                        <td>{value.baggage}</td>
                        <td>{value.refundOrCancel.split('\n').map(i => {return <p>{i}</p>})}</td>
                        <td>{value.changeFlight.split('\n').map(i => {return <p>{i}</p>})}</td>
                        <td>{value.classAirline}</td>
                        <td>
                            <Icon type="edit"  style={{cursor: "pointer"}} onClick={()=>this.showModal(value)} />
                            <br></br>
                            <Icon type="delete" style={{cursor: "pointer"}} onClick={()=>this.delete(value.id)}/>
                        </td>
                    </tr>
                ))
            )
        }
    }


    showModal = (value) => {
        this.setState({
            showModal:true,
            value:value
        })
        this.props.form.resetFields()
    }

    handleCancel = () => {
        
		this.setState({ 
            showModal: false ,
            value:null
        });
    };
    
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                var data = {
                    "baggage":values["baggage"],
                    "refundOrCancel":values["refundOrCancel"],
                    "changeFlight":values["changeFlight"],
                    "classAirline":values["classAirline"],
                    "airlineId":this.state.data[0].id,
                }
                this.setState({loadingCreate:true})
                if (this.state.value === null) {
                    api.addDetail(
                        data
                    ).then(res=>{
                        this.setState({
                            showModal:false,
                            loadingCreate:false,
                            value:null
                        })
                        alert("Thành công!")
                        this.getAirline(this.state.page,this.state.key)
                        
                    }).catch(error=>{
                        console.log(error);
                    })
                }else{
                    var id = this.state.value.id;
                    api.updateDetail(
                        id,data
                    ).then(res=>{
                        this.setState({
                            showModal:false,
                            loadingCreate:false,
                            value:null
                        })
                        alert("Thành công!")
                        this.getAirline(this.state.page,this.state.key)
                        
                    }).catch(error=>{
                        console.log(error);
                    })
                }
            }
        });
            
    };

    delete = (id) => {
        if (window.confirm("Bạn có chắc chắn xóa không ?")) {
            api.deleteDetailAirline(
                id
            ).then(res=>{
                alert("Thành công!")
                this.getAirline(this.state.page,this.state.key)
                
            }).catch(error=>{
                console.log(error);
            })
        }
        
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            this.state.loading 
            ?   <div style={{height: 800, display: 'flex'}}>
                    <div style={{margin: 'auto'}}>
                        <Spin size="large"/>
                    </div>
                </div>
            :
            <div className="container ">
                <div>Hãng hàng không: <b>{this.props.match.params.name}</b></div>
                <div className="row text-right mb-3 mr-2 d-flex flex-row-reverse">
                    <Button key="back" onClick={()=>this.showModal(null)}>
                        Thêm
                    </Button>
                </div>
                <table className="table table-hover border text-center">
                    <thead className="bg-secondary">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Hành lý xách tay</th>
                            <th scope="col">Hoàn trả hoặc hủy bỏ</th>
                            <th scope="col">Thay đổi thông tin chuyến bay</th>
                            <th scope="col">Hạng ghế</th>
                            <th scope="col">Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.showAirline()}
                    </tbody>
                </table>
                
                <Modal
					visible={this.state.showModal}
					title={this.state.value != null ? "Cập nhật thông tin hãng hàng không": 'Thêm mới thông tin hãng hàng không'}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							Đóng
						</Button>,
                        <Button key="submit" htmlType="submit" loading={this.state.loadingCreate} type="primary" onClick={(e)=>this.handleSubmit(e)}>
							{this.state.value != null ? 'Cập nhật': 'Thêm mới'}
						</Button>
					]}
					>
					<Form  className="login-form pl-4">
						<div className="row">
							<Form.Item className="col-12 pr-3 mb-2">
								<span >Hành lý xách tay</span>
								{getFieldDecorator('baggage', {
                                    initialValue:this.state.value === null ? "": this.state.value.baggage,
									rules: [{ required: true, message: 'Vui lòng nhập hành lý xách tay' }],
								})(
									<Input
										placeholder="Nhập hành lý xách tay ..."
									/>,
								)}
							</Form.Item>	
							<Form.Item className="col-12 pr-3 mb-2">
								<span >Hoàn trả hoặc hủy bỏ</span>
								{getFieldDecorator('refundOrCancel', {
                                    initialValue:this.state.value === null ? "": this.state.value.refundOrCancel,
									rules: [{ required: true, message: 'Vui lòng nhập hoàn trả hoặc hủy bỏ' }],
								})(
									<TextArea
										placeholder="Nhập hoàn trả hoặc hủy bỏ ..."
									/>,
								)}
							</Form.Item>	
							<Form.Item className="col-12 pr-3 mb-2">
								<span >Thay đổi thông tin chuyến bay</span>
								{getFieldDecorator('changeFlight', {
                                    initialValue:this.state.value === null ? "": this.state.value.changeFlight,
									rules: [{ required: true, message: 'Vui lòng nhập thay đổi thông tin chuyến bay' }],
								})(
									<TextArea
										placeholder="Nhập thay đổi thông tin chuyến bay ..."
									/>,
								)}
							</Form.Item>	
							<Form.Item className="col-12 pr-3 mb-2">
								<span >Hạng ghế</span>
								{getFieldDecorator('classAirline', {
                                    initialValue:this.state.value === null ? "": this.state.value.classAirline,
									rules: [{ required: true, message: 'Vui lòng hạng ghế' }],
								})(
									<Input
										placeholder="Nhập hạng ghế ..."
									/>,
								)}
							</Form.Item>	
						</div>
					</Form>
				</Modal>
            </div>
        );
    }
}

export default Form.create()(AirlinesDetail);