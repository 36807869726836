import * as util from '../util'
const instance = util.instance

export function getSaleCode(page) {
  return instance
    .get(`sales?page=${page}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}
export function generateCode(number, price, expireDate, image) {
  const data = new FormData();
  data.append("number", number);
  data.append("price", price);
  data.append("expireDate", expireDate);
  data.append("image", image);

    return instance
      .post('/sales/generate',data)
      .then(response => {
        return response.data;
      })
      .catch(util.checkError);
}
export function updateCode(id,data) {
    return instance
      .put(`/sales/sale/${id}`,data)
      .then(response => {
        return response.data;
      })
      .catch(util.checkError);
}
export function deleteSaleCode(id) {
    return instance
      .delete(`/sales/sale/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(util.checkError);
}

