import React from "react";
import '../styles.css'
import * as api from "../../api/country";
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import { Link } from "react-router-dom";
import * as md from "react-icons/md";
import { Button } from 'antd';

export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      totalPages: 1
    };
  }

  componentDidMount() {
    api.getAllCountries(this.state.page - 1).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: response.pageable.pageNumber+1,
          totalPages: response.totalPages
        });
      }
    });
  }

  onPageChange = page => {
    this.setState({ page }, () => {
      api.getAllCountries(this.state.page - 1).then(response => {
        if (response !== undefined) {
          this.setState({
            data: response.content,
            page: response.pageable.pageNumber+1
          });
        }
      });
    });
  };

  renderItem(item) {
    // let des = decode(item.description);
    return (
      <tr className="row m-0" key={item.id}>
        <td className="align-middle col-1">
          {item.id}
        </td>
        <td className="align-middle col-2">
          <img
            className="img-thumbnail"
            src={item.imageUrl}
            alt=""
            width="100"
            height="50"
          />
        </td>
        <td className="align-middle col-3">{item.name}</td>
        <td className="align-middle col-5">
          <div className="max-lines">{item.description}</div>
        </td>
        <td className="align-middle col-1">
          <Link to={"/country/edit/" + item.code} className="m-3">
            <md.MdModeEdit color="black" />
          </Link>
        </td>
      </tr>
    );
  }

  searchCounties = ()=>{
    const {search} = this.state
    api.searchCounties(search, 0).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: response.content.length > 0 ? response.pageable.pageNumber +1 : response.pageable.pageNumber,
          totalPages: response.totalPages
        });
      }
    });
  }

  searchChange=(event)=>{
    var search = event.target.value
    this.setState({ search });
  }

  render() {
    return (
      <div className="container text-center">
        <div className="text-right">
          <input
            className="mr-sm-2"
            type="search"
            placeholder="Tìm kiếm"
            aria-label="Search"
            value={this.state.search}
            onChange={this.searchChange}
          />
          <Button type="primary" className="" onClick={this.searchCounties}>Tìm kiếm</Button>
        </div>
        <table className="table mt-2 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-1">STT</th>
              <th className="col-2">Ảnh</th>
              <th className="col-3">Tên</th>
              <th className="col">Mô tả</th>
              <th className="col-1">Hành động</th>
            </tr>
          </thead>
          <tbody>{this.state.data.map(item => this.renderItem(item))}</tbody>
        </table>
        <ReactUltimatePagination
          currentPage={this.state.page}
          totalPages={this.state.totalPages}
          onChange={this.onPageChange}
        />
      </div>
    );
  }
}
