import React from "react";
import "./styles.css";
import * as api from "../../api/delay";
import _ from 'lodash'
import { Button, Modal, Radio } from 'antd';

const NO_DISABLE = "";
const DISABLE = "disabled-div";

export default class customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpenCustomer: false
    };
  }

  componentDidMount() {
    
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.delayInsurance !== null) {
      this.setState({
        id : nextProps.delayInsurance.id, 
        requestId : nextProps.delayInsurance.requestId, 
        saleOrderId : nextProps.delayInsurance.saleOrderId, 
        paymentAmount : nextProps.delayInsurance.paymentAmount, 
        totalPrice : nextProps.delayInsurance.totalPrice, 
        paymentInsurance : nextProps.delayInsurance.paymentInsurance, 
        activeInsurance : nextProps.delayInsurance.activeInsurance, 
      });
    }
  }

  editDelayInsurance = () => {
    this.setState({ 
      modalIsOpenCustomer: true,
    });
  }

  closeModalCustomer = () => {
    this.setState({ modalIsOpenCustomer: false });
  }

  onChangePaymentInsurance = e => {
    this.setState({paymentInsurance: e.target.value});
  };

  onChangeActiveInsurance = e => {
    this.setState({activeInsurance: e.target.value});
  };

  updateDelayInsurance = () =>{
    const {
      id, 
      paymentInsurance, 
      activeInsurance, 
    } = this.state;
    var obj = {paymentInsurance, activeInsurance};
    api.update(id, obj).then(response => {
      if (response !== undefined) {
        alert("Cập nhật thành công");
        this.closeModalCustomer();
      }
    });
  }

  render() {
    const {
      id, 
      requestId, 
      saleOrderId, 
      paymentAmount, 
      totalPrice, 
      paymentInsurance, 
      activeInsurance, 
    } = this.state;
    return (
      <div>
        <Button type="primary" className={this.props.delayInsuranceId  > 0 ? NO_DISABLE : DISABLE } onClick={this.editDelayInsurance}>Thông tin bảo hiểm</Button>

        <Modal
          visible={this.state.modalIsOpenCustomer}
          confirmLoading={false}
          onCancel={this.closeModalCustomer}
          footer={[
            <Button key="back" onClick={this.closeModalCustomer}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.updateDelayInsurance}>
              Lưu
            </Button>
          ]}
        >
        <div className="mt-3">
          <h6>Tổng giá</h6>
          <p>{totalPrice}</p>

          <h6 className="mt-3">Thanh toán</h6>
          <Radio.Group onChange={this.onChangePaymentInsurance} value={paymentInsurance}>
            <Radio value={true}>Đã thanh toán</Radio>
            <Radio value={false}>Chưa thanh toán</Radio>
          </Radio.Group>

          <h6 className="mt-3">Kích hoạt yêu cầu mua</h6>
          <Radio.Group onChange={this.onChangeActiveInsurance} value={activeInsurance}>
            <Radio value={true}>Đã kích hoạt</Radio>
            <Radio value={false}>Chưa kích hoạt</Radio>
          </Radio.Group>
          </div>
        </Modal>
      </div>
    );
  }
}
