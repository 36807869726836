import React from "react";
import * as api from "../../api/blog";
import * as apiSugges from "../../api/sussgest";
import _ from 'lodash'
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import { Link } from "react-router-dom";
import { Modal, Icon, Button, Checkbox } from 'antd';
const confirm = Modal.confirm;
var moment = require('moment');

const dateFormat = 'hh:mm-DD/MM/YYYY';
const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DDTHH:mm:ss.SSSZ`];

const SIZE = 6;

export default class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      totalPages: 1,
      loading: false,
      search: '',
      title: "", 
      description: "", 
      promotions: [],
      id: 1
    };
  }

  componentDidMount() {
      this.getDetail()
  }

  getDetail(){
    const { id } = this.state;
    apiSugges.getSuggestById(id).then(response => {
      if (response !== undefined) {
        this.setState({
          title: response.title, 
          description: response.description, 
          promotions: response.promotions,
          id: response.id
        });
      }
    });
  }

  getAllPromotion(){
    var page = 0
    api.getAllPromotion(page, false).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: page === 0 && response.content.length === 0 ? response.pageable.pageNumber : response.pageable.pageNumber + 1,
          totalPages: response.totalPages
        });
      }
    });
  }

  onPageChange = page => {
    this.setState({ page }, () => {
      api.getAllPromotion(this.state.page - 1, false).then(response => {
        if (response !== undefined) {
          this.setState({
            data: response.content,
            page: response.pageable.pageNumber +1
          });
        }
      });
    });
  };

  showDeleteConfirm(id) {
    confirm({
      title: 'Cảnh báo!',
      content: 'Bạn có chắc chắn muốn xoá?',
      okText: 'Có',
      okType: 'danger',
      cancelText: 'Không',
      onOk:()=> {
        this.deletePromotion(id);
      },
      onCancel() {
        
      },
    });
  }

  deletePromotion = id => {
    
  };

  onChangeCheckbox = (e, index)=>{
    if(this.state.modalIsOpenCustomer){
      var data = this.state.data
      data[index].unPublish = e.target.checked
      this.setState({data})
    }else {
      var promotions = this.state.promotions
      promotions[index].unPublish = e.target.checked
      this.setState({promotions})
    }
  }

  renderItem(item, index) {
    var date = item.datePublish
    return (
      <tr key={item.id} className="row m-0">
        <td className="align-middle col-1">
          <Checkbox onChange={(e)=>this.onChangeCheckbox(e, index)}/>
        </td>
        <th className="align-middle col-2" scope="row">
          {moment(date, dateFormatList).format(dateFormat)}
        </th>
        <td className="align-middle col-3">
          <img
            className="img-thumbnail"
            src={item.image.thumblink}
            alt=""
            width="100"
            height="50"
          />
        </td>
        <td className="align-middle col-3">{item.title}</td>
        <td className="align-middle col-3">{item.subDescription}</td>
      </tr>
    );
  }

  searchBlog = ()=>{
    const {search} = this.state
    this.setState({ loading: true }, () => {
      api.searchPromotion(search, 0, false).then(response => {
        if (response !== undefined) {
          this.setState({
            data: response.content,
            page: response.content.length === 0 ? response.pageable.pageNumber : response.pageable.pageNumber + 1,
            totalPages: response.totalPages,
            loading: false
          });
        }
      }).catch = (error)=>{
        this.setState({
          loading: false
        });
      };
    });
  }

  publishBlog=()=>{
    const{data} = this.state;
    api.publishPromotion(data).then(response => {
      if (response !== undefined) {
        this.componentDidMount()
        alert(response.message);
      }
    });
  }

  searchChange=(event)=>{
    var search = event.target.value
    this.setState({ search });
  }

  handleChangeTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  handleChangeDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  openDialog=()=>{
    this.setState({ 
      modalIsOpenCustomer: true,
    });
    this.getAllPromotion()
  }

  addBlog=()=>{
    var {promotions, data} = this.state;
    var dataTemp = _.remove(data, function(item) {
      return item.unPublish === true;
    });
    if(promotions.length + dataTemp.length > SIZE){
      alert("Số blog được chọn không quá 6")
      return;
    }
    promotions = dataTemp.concat(promotions)
    this.setState({promotions, data, modalIsOpenCustomer: false})
  }

  deleteBlog=()=>{
    var promotions = _.remove(this.state.promotions, function(item) {
      return !item.unPublish;
    });
    this.setState({promotions})
  }

  closeModalCustomer = () => {
    this.setState({ modalIsOpenCustomer: false });
  }

  saveSuggest = ()=>{
    // const { id } = this.props.match.params;
    const {title,description,promotions,id} = this.state

    if(title===""){
      alert("Tiêu đề không được bỏ trống!")
      return;
    }

    if(description===""){
      alert("Mô tả không được bỏ trống!")
      return;
    }

    if(promotions.length !== 6){
      alert("Danh sách các bài viết phải là 6!")
      return;
    }

    apiSugges.editSuggest(id, title, description, promotions).then(response => {
        if (response !== undefined) {
          alert(response.message)
        }
      });
    
  }

  render() {
    const{title, description, promotions, data} = this.state
    promotions.map((item) =>{
      _.remove(data, function(n) {
        return n.id === item.id;
      });
    })
    return (
      <div className="container">
        <h4>THÔNG TIN</h4>
        <div className="">
        <h6 className="">Tiêu đề:</h6>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={event => this.handleChangeTitle(event)}
          />
        </div>

        <div className="mt-3">
          <h6 className="">Mô tả:</h6>
          <input
            type="text"
            className="form-control"
            value={description}
            onChange={event => this.handleChangeDescription(event)}
          />
        </div>
        
        <div className="text-right mt-4">
          <Button type="primary" onClick={this.deleteBlog}> Xoá </Button>
          <Button type="primary" className="ml-2" onClick={this.openDialog}> Thêm mới </Button>
        </div>

        <table className="table mt-2 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-1"></th>
              <th className="col-2">Ngày duyệt</th>
              <th className="col-3">Ảnh</th>
              <th className="col-3">Tiêu đề</th>
              <th className="col-3">Nội dung</th>
            </tr>
          </thead>
          <tbody>{promotions.map((item,index) => this.renderItem(item, index))}</tbody>
        </table>

        <Button type="primary" className="ml-2" onClick={this.saveSuggest}>Lưu</Button>

        <Modal
          width={800}
          visible={this.state.modalIsOpenCustomer}
          confirmLoading={false}
          onCancel={this.closeModalCustomer}
          footer={[
            // <Button key="back" onClick={this.closeModalCustomer}>
            //   Huỷ
            // </Button>,
            // <Button key="submit" type="primary" loading={this.state.loading} onClick={this.createCustomer}>
            //   Lưu
            // </Button>
          ]}>
          <div/>
          <div className="text-right mt-3">
            <input
              className="mr-sm-2 mt-3"
              type="search"
              placeholder="Tìm kiếm"
              aria-label="Search"
              value={this.state.search}
              onChange={this.searchChange}
            />
            <Button type="primary" className=" mt-2" loading={this.state.loading} onClick={this.searchBlog}>Tìm kiếm</Button>

            <Button type="primary" className=" mt-2 ml-5" onClick={this.addBlog}>Thêm</Button>

          </div>
          <table className="table mt-2 table-hover table-bordered">
            <thead className="thead-dark">
              <tr className="row m-0">
                <th className="col-1"></th>
                <th className="col-2">Ngày duyệt</th>
                <th className="col-3">Ảnh</th>
                <th className="col-3">Tiêu đề</th>
                <th className="col-3">Nội dung</th>
              </tr>
            </thead>
            <tbody>{this.state.data.map((item,index) => this.renderItem(item, index))}</tbody>
          </table>
          <ReactUltimatePagination
            currentPage={this.state.page}
            totalPages={this.state.totalPages}
            onChange={this.onPageChange}
          />
        </Modal>
      </div>
    );
  }
}