import React from "react";
import * as api from "../../api/sale";
import SelectImage from "../../components/select_Image"
import NumericInput from '../../components/inputtext_number'
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import './style.css'
import _ from 'lodash'
import { Modal, Button, Icon, DatePicker } from 'antd';
const confirm = Modal.confirm;
var moment = require('moment');

const dateFormat = 'hh:mm-DD/MM/YYYY';
const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DDTHH:mm:ss.SSSZ`];

export default class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      totalPages: 1,
      loading: false,
      search: '',
      number: '',
      price: '',
      name: "",
      image: undefined,
      modalIsOpen: false,
      isAdd: true,
      id: 0,
      expireDate: moment()
    };
  }

  componentDidMount() {
    this.getSaleCodes(0)
  }

  getSaleCodes(page){
    api.getSaleCode(page).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: page === 0 && response.content.length === 0 ? response.pageable.pageNumber : response.pageable.pageNumber + 1,
          totalPages: response.totalPages
        });
      }
    });
  }

  onPageChange = page => {
    this.setState({ page }, () => {
      api.getSaleCode(this.state.page - 1).then(response => {
        if (response !== undefined) {
          this.setState({
            data: response.content,
            page: response.pageable.pageNumber +1
          });
        }
      });
    });
  };

  showDeleteConfirm(id) {
    confirm({
      title: 'Cảnh báo!',
      content: 'Bạn có chắc chắn muốn xoá?',
      okText: 'Có',
      okType: 'danger',
      cancelText: 'Không',
      onOk:()=> {
        this.deleteSaleCode(id);
      },
      onCancel() {
        
      },
    });
  }

  deleteSaleCode = id => {
    api.deleteSaleCode(id).then(response => {
      if (response !== undefined) {
        alert(response.message);
        this.getSaleCodes(0)
      }
    });
  };

  getSaleById = id => {
    let item = _.find(this.state.data, {id});
    this.setState({ id, name: item.name, image: item.imageUrl, isAdd: false, modalIsOpen: true });
  };

  generateCode = () => {
    this.setState({ loading: true });
    const {id, image, number, expireDate, price} = this.state
    if(number===""){
      alert("Bạn chưa nhập số lượng mã")
      return;
    }
    if(price===""){
      alert("Bạn chưa nhập giá của mã")
      return;
    }
    console.log(typeof image)
    if(typeof image === "string" || image === undefined){
      alert("Bạn chọn lại ảnh")
      return;
    }
    api.generateCode(number, price, expireDate, image).then(response => {
        if (response !== undefined) {
          alert(response.message);
          this.getSaleCodes(0)
        }
      });
  }

  renderItem(item, index) {
    return (
      <tr key={item.id} className="row m-0">
        <td className="align-middle col-2">{item.code}</td>
        <td className="align-middle col-2">{moment(item.expireDate, dateFormatList).format(dateFormat)}</td>
        <td className="align-middle col-3">
          <img
            className="img-thumbnail"
            src={item.imageUrl}
            alt=""
            width="100"
            height="50"
          />
        </td>
        <td className="align-middle col-1">{item.price}</td>
        <td className="align-middle col-2">{item.typeCode}</td>
        <td className="align-middle col-1">{item.used ? "Đã sử dụng" : "Chưa sử dụng"}</td>
        <td className="align-middle col-1">
          {/* <Icon type="edit" onClick={() => {this.getSaleById(item.id);}}/>
          <br/> */}
          <Icon type="delete" onClick={() => {this.showDeleteConfirm(item.id)}}/>
        </td>
      </tr>
    );
  }

  handleChangeName =(event)=>{
    this.setState({ name: event.target.value });
  }

  returnImage = (image) => {
    this.setState({image})
  }

  openModal = () => {
    this.setState({ modalIsOpen: true, name: '', image: undefined, isAdd: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  handleChangeNumber = (value) => {
    this.setState({ number: value });
  };

  handleChangePrice = (value) => {
    this.setState({ price: value });
  };

  handleChangeExpireDate = (date, dateString) => {
    if(date != null)
      this.setState({ expireDate: date.format("YYYY-MM-DDTHH:mm:ss.SSS") });
  };

  selectImages = event => {
    let image = event.target.files.item(0);
    if(image != null){
      image.name.match(/\.(jpg|jpeg|png|gif)$/);
      this.setState({ image });

      this.returnImage(
        typeof image === "string" ? null : image,
        image.name
      );
    }
  };

  render() {
    const {name, image, number, expireDate, price} = this.state
    return (
      <div className="container text-center">
        <div className="row text-right d-flex justify-content-end">
          
          <NumericInput className="mr-2 col-2" value={number} onChange={this.handleChangeNumber} placeholder="Số lượng mã"/>
          <NumericInput className="mr-2 col-2" value={price} onChange={this.handleChangePrice} placeholder="Giá"/>
          <DatePicker value={moment(expireDate, dateFormatList)} format={dateFormat} onChange={this.handleChangeExpireDate}/>
          <div className="custom-file text-left mr-2 ml-2 col-2 my-auto" style={{height: 32}}>
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              name="filename"
              style={{maxHeight: '100%'}}
              onChange={this.selectImages}
            />
            <label className="custom-file-label" htmlFor="customFile" style={{maxHeight: '100%'}}>
              {image !== undefined ? image.name : 'Chọn ảnh'}
            </label>
          </div>
          <Button type="primary" className="mr-3" onClick={this.generateCode}>+ Thêm</Button>
        </div>
        <table className="table mt-2 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-2">Code</th>
              <th className="col-2">Hết hạn</th>
              <th className="col-3">Ảnh</th>
              <th className="col-1">Giá</th>
              <th className="col-2">Loại</th>
              <th className="col-1">Đã sử dụng</th>
              <th className="col-1">Hành động</th>
            </tr>
          </thead>
          <tbody>{this.state.data.map((item, index) => this.renderItem(item, index))}</tbody>
        </table>
        <ReactUltimatePagination
          currentPage={this.state.page}
          totalPages={this.state.totalPages}
          onChange={this.onPageChange}
        />
        <Modal
          visible={this.state.modalIsOpen}
          onOk={this.generateCode}
          confirmLoading={false}
          onCancel={this.closeModal}
          footer={[
            <Button key="back" onClick={this.closeModal}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" onClick={this.generateCode}>
              Lưu
            </Button>,
          ]}
        >
          <h6>Tên</h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={name}
            onChange={this.handleChangeName}
          />
          <SelectImage image={image} returnImage={this.returnImage}/>
        </Modal>
      </div>
    );
  }
}
