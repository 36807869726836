import * as util from '../util'
const instance = util.instance

export function createBanner(image, name) {
  const data = new FormData();
  data.append("image", image);
  data.append("name", name);
  data.append("priority", 1);

  return instance
    .post("banners/", data)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function updateBanner(id, image, name) {
  console.log(name)
  image = typeof image === "string" ? null : image
  const data = new FormData();
  if(image != null){
    data.append("image", image);
  }
  data.append("name", name);
  data.append("priority", 1);

  return instance
    .put(`banners/${id}`, data)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function deleteBanner(id) {
  return instance
    .delete(`banners/${id}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getBanner() {
  return instance
    .get(`banners/`)
    .then(response => {
        return response.data;
    })
    .catch(util.checkError);
}

export function getBannerById(id) {
  return instance
    .get(`banners/${id}`)
    .then(response => {
        return response.data;
    })
    .catch(util.checkError);
}
