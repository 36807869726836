import * as util from '../util'
const instance = util.instance

export function getBooks(page) {
  return instance
    .get(`books/?page=${page}`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function getCurrentBook(page) {
  return instance
    .get(`books/today?page=${page}`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function getBookById(id) {
  console.log("id", id)
  return instance
    .get(`book-by-code/${id}`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function editFlight(id, obj) {
  return instance
    .put(`books/${id}`, obj)
    .then(response => {
      return response.data;
    })
}

export function editBook(id, obj) {
  return instance
    .put(`books/${id}`, obj)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function createBook(obj) {
  return instance
    .post(`books`, obj)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function deleteBook(id) {
  return instance
    .delete(`books/${id}`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function searchBook1(obj){
  return instance
    .post(`books/search?page=${obj.page}`, obj)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function profit(obj){
  return instance
    .post(`books/complete`, obj)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function searchBook(type, status, staffId, page, key){
  var obj = {type, status, staffId, key}
  return instance
    .post(`books/search?page=${page}`, obj)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function updateBookStatus(books){
  var ids = []
  books.map((item, index) => {
    if(item.cskh){
      ids.push(item.id)
    }
  })
  var obj = {ids}
  return instance
    .post(`books/updateStatus`, obj)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function getBookByGroup(page, status) {
  return instance
    .get(`groupTicket?page=${page}&&status=${status}`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function updateGroupTicket(id, object) {
  return instance
    .put(`groupTicket/update/${id}`, object)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function returnTicket(obj){
  return instance
    .post(`books/returnTicket`, obj)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function issueTheTicketNoCode(code){
  return instance
    .post(`book/issueTheTicketNoCode?code=${code}`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}