import * as util from '../util'
const instance = util.instance

export function editProvince(id, image, subDes, description, region) {
  const data = new FormData();
  if(image != null){
    data.append("image", image);
  }
  data.append("subDescription", subDes);
  data.append("description", description);
  data.append("region", region);

  return instance
    .post(`provinces/${id}`, data)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getProvincesByCountryCode(code) {
  return instance
    .get(`provinces/countryCode/${code}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getProvince(code) {
  return instance
    .get(`provinces/${code}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function searchProvince(key) {
  return instance
    .get(`provinces/search?key=${key}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function searchProvinceAll(key) {
  return instance
    .get(`provinces/searchAll?key=${key}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}