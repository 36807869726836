import React from "react";
import login from '../../api/auth'
import { Route } from 'react-router-dom'
import Background from '../../images/lmg_login.png';
import CustomButton from '../../components/button'
import { invitationByUser } from "../../api/invite";
import Cookies from 'js-cookie'

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
  }

  componentDidMount(){
    localStorage.clear();
  }

  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
  };

  handleChangePassword = event => {
    this.setState({ password: event.target.value });
  };

  handleLogin =(history)=> {
    const {email, password} = this.state
    if(email===""){
      alert("Email không để trống")
      return
    }

    if(password===""){
      alert("Mật khẩu không để trống")
      return
    }
    login(email, password).then(response => {
      if (response !== undefined) {
        if(response.data.user.roles[0].id == 5){
          Cookies.set('user', JSON.stringify(response.data), { expires: 7 })     
          localStorage.setItem('user', JSON.stringify(response.data))
          invitationByUser().then(response => {
            localStorage.setItem('agent', response.data.phone)
            if (response !== undefined) {
              history.push('/invite/book/'+ response.data.phone)
            }
          });
        }else{
          alert("Bạn không có quyền truy cập!")
        }
      }
    });
  }

  render() {
    return (
      <div style={{backgroundImage: `url(${Background})`, top:'0', bottom:'0', left:'0', right:'0', position: 'absolute'}}>
        <div className="container text-center shadow p-3 mb-3 mt-3 bg-white rounded w-50" >
          <h1 style={{color: '#F47920'}}>Đăng nhập</h1>
          <div className="form-group row">
            <label className="col-2" for="usr">Email:</label>
            <input type="text" className="form-control col mr-5" id="usr" value={this.state.email} onChange={this.handleChangeEmail}/>
          </div>
          <div className="form-group row">
            <label className="col-2" for="pwd">Mật khẩu:</label>
            <input type="password" className="form-control col mr-5" id="pwd" value={this.state.password} onChange={this.handleChangePassword}/>
          </div>
          <Route render={({ history}) => (
            <CustomButton text="Đăng nhập" onClick={() => { this.handleLogin(history) }}/>
          )} />
        </div>
      </div>
    );
  }
}
