import React from "react";
import { Button } from 'antd';
import "./styles.css";

class CustomButton extends React.Component {
  render() {
    return (
      <Button type="primary" onClick={this.props.onClick}>{this.props.text}</Button>
    );
  }
}

export default CustomButton;
