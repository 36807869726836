import * as util from "../util";
const instance = util.instance;

export function promotion(image, title, content, subDes, category, slug) {
  const data = new FormData();
  data.append("image", image);
  data.append("title", title);
  data.append("description", content);
  data.append("subDescription", subDes);
  data.append("categoryId", category);
  data.append("slug", slug);

  return instance
    .post("promotions", data)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function updatePromotion(id, image, title, content, subDes, category) {
  const data = new FormData();
  if (image != null) {
    data.append("image", image);
  }
  data.append("title", title);
  data.append("description", content);
  data.append("subDescription", subDes);
  data.append("categoryId", category);

  return instance
    .put(`promotion/${id}`, data)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function publishPromotion(idPromotions) {
  return instance
    .post(`promotion/publish`, { idPromotions })
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function deletePromotion(id) {
  return instance
    .delete(`promotion/${id}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getAllPromotion(page, isCreated) {
  return instance
    .get(`promotions?page=${page}&&isCreated=${isCreated}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function searchPromotion(key, page, isCreated) {
  return instance
    .get(`promotionSearch?page=${page}&&key=${key}&&isCreated=${isCreated}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getPromotionById(id) {
  return instance
    .get(`promotion/${id}`)
    .then(response => {
      return response.data;
    })
    .catch(util.checkError);
}

export function getAllCategory() {
  return instance
    .get(`promotionsCategory`)
    .then(response => {
      var arr = [];
      response.data.map((item, index) => {
        arr[index] = { id: item.id, name: item.name, isCheck: false };
      });
      return arr;
    })
    .catch(this.checkError);
}

export function uploadImage(image) {
  const data = new FormData();
  data.append("file", image);

  return instance.post("publish/uploadImage", data).then(response => {
    return response.data;
  });
}
